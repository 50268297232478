.product-dropdown .dropdown-menu {
    margin: 0.1rem 0 0 0; /* 1px */
    position: fixed !important;
    top: 9.8rem !important;
    left: 0 !important;
    transform: none !important;
    width: 100% !important;

    padding: 1rem 1.5rem 1rem 7rem /* 10px 15px 10px 70px */ !important;
    overflow: auto;
    max-height: 70% !important;
    font-size: 1.5rem; /* 15px */
    box-shadow: 0 10rem 10rem 0 /* 100px 100px */ rgba(0,0,0,.4) !important;
}


.dropdown-menu>li>a:hover {
background-color:transparent !important;
}

.btn-toolbar {
display: flex;
justify-content: flex-start;
}

.btn-toolbar>.btn, .btn-toolbar>.btn-group, .btn-toolbar>.input-group {
margin-left: 2rem; /* 20px */
}
.dropdown-item {
display: block;
width: 100%;
/* padding: .25rem 1.5rem; */
padding: 0.4rem 2.4rem; /* 4px 24px */
clear: both;
font-weight: 400;
color: #212529;
text-align: inherit;
white-space: nowrap;
background-color: transparent;
border: 0;
}

.dropdown-toggle:after {
display: none !important;
margin-left: .255em;
vertical-align: .255em;
content: "";
border-top: .3em solid;
border-right: .3em solid transparent;
border-bottom: 0;
border-left: .3em solid transparent;
}

.btn-primary, .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {
color:black!important;
background-color: transparent !important;
border-color: transparent !important;
text-transform: none !important;
box-shadow: none !important;
padding: 0rem 0rem /* 0px 0px */ !important;
/* font-size: 1.3rem; */
font-size: 2.08rem; /* 20.8px */
/* font-family: "Avenir"; */
    font-size: 1.5rem; /* 15px */
    border-bottom: 0.5rem /* 5px */ solid transparent !important;
    line-height: -0.1rem; /* -1px */
}

:focus{
    outline: white !important;    
}

.btn-primary:hover {
    /* color: #fbc715 !important; */
    background-color: transparent !important;
    /* border-radius: 2px; */
    box-shadow: none !important;
}

.product-dropdown-menu{
    padding-right: 5.5rem; /* 55px */
}

.product-dropdown{
    /* margin-bottom: 3px !important; */
    height:3rem /* 30px */ !important;
    transition: 0.1ms;;
}

.product-dropdown:hover{
    border-bottom: 0.4rem /* 4px */ solid #535876 !important;
}
.fixed-top, .sticky-top{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    /* z-index: 999; */
    transition: position .8s linear;
}


.navigation-search-input {
    box-shadow: none;
    font-size: 1.3rem; /* 13px */
    padding-top: 1.5rem; /* 15px */
    font-style: italic;
    border: none !important;
    width:100%;
}
.navigation-search-input:focus {
    box-shadow: none;
    font-size: 1.4rem; /* 14px */
    transition: all 0.4s;
  }
  
.navigation-search-underlined {
    border-width: 0;
    border-bottom-width: 0rem; /* 0px */
    border-radius: 0;
    padding-left: 0;
  }

.dropdown-divider {
    height: 1.1rem; /* 11px */
    margin: 0rem 0rem 0.2rem 0rem; /* 0px 0px 2px 0px */
    border-top: 0.1rem solid transparent;
}

.category{
    margin-left:0rem; /* 0px */
    color:#616161cc;
    /* margin-bottom: 2.5rem; */
    margin-bottom: 4rem; /* 40px */
    text-transform: uppercase;

    /* border-bottom: 1px solid gray; */
    /* text-transform: uppercase; */
}

.manuals{
    background:transparent;
    padding-left: 0rem; /* 0px */
    padding-right: 1rem; /* 10px */
}

.manual-info{
    color:#000000;
    font-size: 1.5rem; /* 15px */
}

.manuals:hover .manual-info{
    /* background: grey; */
    color:#009DFF !important;
}

.search-description{
    font-style: italic;
    font-size: 1.2rem; /* 12px */
    color: #A9A9A9;
    margin-left: 1.5rem; /* 15px */
}

.label-search-category{
    font-size: 1.2rem; /* 12px */
    border: 0.1rem /* 1px */ solid;
    background: #535876;
    color: white;
    padding: 0.3rem 1rem 0.3rem 1rem; /* 3px 10px 3px 10px */
    border-radius: 0.6rem; /* 6px */
    float: right;
    margin:-0.15rem 0rem 0rem 0rem; /* -1.5px 0px 0px 0px */
}


.menu-search-list{
    color: #18032d;
    padding:0.7rem 1.5rem 0.7rem 2rem;
 }
.menu-search-list:hover{
    color:#009DFF;
}

.Collapsible{
    margin: 0rem 0rem 0rem 3rem; /* 0px 0px 0px 30px */
    padding: 1rem 0rem 1rem 0rem; /* 10px 0px 10px 0px */
}

.Collapsible>span {
    text-transform: uppercase;
    letter-spacing: 0.3rem; /* 3px */
    color: #A9A9A9;
    /* font-size: 1rem; */
    font-size: 1.6rem; /* 16px */
    text-align: left;
    display: block;
    border-bottom: 0.1rem /* 1px */ solid #8080801f;
}

.Collapsible__trigger:after {
    content: '\2304';
        right: 1rem; /* 10px */
    top: 1rem; /* 10px */
    transition: transform 300ms;
    margin-right: 3rem; /* 30px */
    float: right;
}

.is-open.Collapsible__trigger:after {
    content: '\2303';
    right: 1rem; /* 10px */
    top: 1rem; /* 10px */
    transition: transform 300ms;
    margin-right: 3rem; /* 30px */
    float:right;
}

.playbook-search-menu {
    width: 30rem;
    height: 3.6rem;
    padding: 2px 8px;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
}

.playbook-search-menu:hover{
    border-color: hsl(0,0%,70%);
}