
.content-wrapper {
    min-height: 93.5vh;
    background-color: white;
 }
 
 @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,400;1,100&display=swap');
 
 body {
     /* font-family: 'Avenir'; */
     font-weight: 300;
 }
 
 .dropdown-large {
     position: static !important;
 }
 
 .dropdown-menu-large {
     margin-left: 1.6rem; /* 16px */
     margin-right: 1.6rem; /* 16px */
     padding: 2rem 0rem; /* 20px 0px */
 }
 
 .dropdown-menu-large>li>ul {
     padding: 0;
     margin: 0;
 }
 
 .dropdown-menu-large>li>ul>li {
     list-style: none;
 }
 
 .dropdown-menu-large>li>ul>li>a {
     display: block;
     padding: 0.3rem 2rem; /* 3px 20px */
     clear: both;
     font-weight: 300;
     line-height: 1.428571429;
     color: #333333;
     white-space: normal;
 }
 
 .dropdown-menu-large>li ul>li>a:hover,
 .dropdown-menu-large>li ul>li>a:focus {
     text-decoration: none;
     color: #262626;
     background-color: #f5f5f5;
 }
 
 .dropdown-menu-large .disabled>a,
 .dropdown-menu-large .disabled>a:hover,
 .dropdown-menu-large .disabled>a:focus {
     color: #999999;
 }
 
 .dropdown-menu-large .disabled>a:hover,
 .dropdown-menu-large .disabled>a:focus {
     text-decoration: none;
     background-color: transparent;
     background-image: none;
     filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
     cursor: not-allowed;
 }
 
 .dropdown-menu-large .dropdown-header {
     color: #428bca;
     font-size: 1.8rem; /* 18px */
 }
 
 .card-link:hover {
     color: #18032d !important;
 }

 .modal-header .close {
    font-size: 2.5rem;
  }
 
 @media (max-width: 768px) {
     .dropdown-menu-large {
         margin-left: 0;
         margin-right: 0;
     }
 
     .dropdown-menu-large>li {
         margin-bottom: 3rem; /* 30px */
     }
 
     .dropdown-menu-large>li:last-child {
         margin-bottom: 0;
     }
 
     .dropdown-menu-large .dropdown-header {
         padding: 0.3rem 1.5rem /* 3px 15px */ !important;
     }
 }
 
 nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
     width: 122%;
     /* margin-left: -13rem; */
     margin-left: -20.8rem; /* -208px */
 }
 
 .active-product {
     border-bottom: 0.2rem /* 2px */ solid #FCCE31;
 }
 
 .navbar{
     padding-top: 1.5rem /* 15px */ !important;
 }
 a.list-group-item {
     height: auto;
     min-height: 15rem; /* 150px */
     overflow: hidden;
     border: none;
     margin: 0rem 5rem; /* 0px 50px */
     text-decoration: none;
     background: transparent;
     
 }
 
 a.list-group-item:hover{
     color:black;
 }
 
 a.list-group-item.active small {
     color: #fff;
 }
 
 .stars {
     margin: 2rem auto 0.1rem; /* 20px 1px */
 }
 
 .features ul {
     padding-left: 0.4rem; /* 4px */
     list-style: none;
 }
 .label-warning {
     background-color: #535876;
 }
 
 
 @media screen and (max-width: 768px){
     a.list-group-item{
         height: auto;
         min-height: 15rem; /* 150px */
         overflow: hidden;
         border: none;
         margin: 0rem 0rem; /* 0px 0px */
         text-decoration: none;
         background: transparent;
     }
 }