.d-flex {
    display: flex;
    align-items: center;
  }
  


.dropdown-menu-available-versions{
    min-width: min-content;
    border: none;
    border-radius: 0rem; /* 0px */
    max-height: min-content;
    box-shadow: none;
    padding: 0;
}
   
   
.dropdoown-item-available-versions{
    width: 10rem /* 100px */ !important;
    max-height: 10rem; /* 100px */
    overflow: scroll;
    border: none;
    background: #494b5ae3 !important;
    margin-bottom:0rem; /* 0px */
}
   

.dropdown-menu-version-input{
    width:10rem; /* 100px */
    height: 3rem; /* 30px */
    margin-bottom: 0rem /* 0px */ !important;
    font-size: 1.2rem; /* 12px */
    border:none !important;
    background:transparent;
    background: #494b5ae3 !important;
    color:#FCCE31 !important;
    border-radius: 0rem /* 0px */ !important;
}

.dropdown-menu-version-input::placeholder{
    color:#ffffff !important;
    font-style: italic;
    font-size: 1.2rem; /* 12px */
}

.dropdown-menu-version-input:focus{
    box-shadow: none !important;
}


.dropdown-menu-version-item{
    color:white;
    font-size: 1.2rem; /* 12px */
}

.dropdown-menu-version-item:hover{
    color: #FCCE31;
    text-decoration: none;
    background-color: #3b3b44;
    
}

.available-version-dropdown-button:hover{
    text-decoration: none;
    color: black;
}