#search-results em {
    display: inline-block;
    font-weight: 600;
    color:#0199b0;
    font-style: normal;
    font-family:avenir;
}

.resultItem:hover{
    background: #f8f9fa;
}
.form-control {
    height: 3.8rem; /* 38px */
}
.version-badge:focus, .version-badge:hover {
    text-decoration: none !important;
    background-color: #575b71 !important;
}

.version-current {
    font-size: 1.4rem; /* 14px */
    font-weight: 200;
    letter-spacing: 0.05rem; /* 0.5px */
    color: #616161;
    background: inherit;
    padding: 0.3rem; /* 3px */
    margin-left: 0.5rem; /* 5px */
    border: 0.1rem /* 1px */ solid #616161;
    opacity: 0.5;
}

.label-info{
    margin: 0.3rem 0.5rem 0rem 0rem; /* 3px 5px 0px 0px */
    padding: 0.3rem 0.6rem 0.3rem 0.6rem; /* 3px 6px 3px 6px */
    /* font-size: 1.1rem; */
    font-size: 1.76rem; /* 17.6px */
    border-radius: 0.5rem; /* 5px */
    font-style: italic;
    background-color: #FCCE31;
}

.card-available-version{
    font-size: 85%;
    text-align: left;
    /* background-image: linear-gradient(to left, rgb(179, 197, 211), #FFFFFF); */
    border: none;
}

.available-versions:hover{
    color:#f0ad4e !important;
}


.version{
    color:black;
    background:  #ffffff;
    padding: 0.5rem 1.2rem 0.3rem 1.2rem; /* 5px 12px 3px 12px */
    border: 0.1rem /* 1px */ solid black;
}

.available-versions{
    font-size: 90%;
    margin: 0.5rem 0rem 0rem 1.5rem; /* 5px 0px 0px 15px */
}

.available-versions-dropdown-button{
    font-size: 2rem; /* 20px */
    margin: 0.5rem 0rem 0rem 1.5rem; /* 5px 0px 0px 15px */
    font-weight: 400;
}

.available-versions-dropdown-button:hover{
    text-decoration: none !important;
    color:black;
}

.version:hover{
    background: #535876;
    color:white !important;
    text-decoration: none;
}

.version-badge{
    background-color: #696e88;
    font-size: 90%;
    padding: 0.6rem 1.5rem; /* 6px 15px */
    border-radius: 0rem; /* 0px */
    text-transform: capitalize;

}

.version-card{
    margin-top:0rem; /* 0px */
    background: transparent;
    border: none;
}
.search-result-guide-path{
    margin-bottom: 20rem; /* 200px */
    font-size: 1.4rem; /* 14px */
    white-space: pre-wrap;
    font-weight: 600;
    letter-spacing: 0.05rem; /* 0.5px */
    color: black;
}

.search-result-title{
    font-size: 1.4rem; /* 14px */
    font-style: italic;
    font-weight: 600;
    color: black;
}

.search-result-texts{
    font-size: 1.3rem; /* 13px */
    color:black;
    margin-bottom: 0rem; /* 0px */
    line-height: 1.7rem; /* 17px */
}


.search-version-card{
    margin-left: 0; 
    /* margin-top: 3rem; */
    margin-top: 4.8rem; /* 48px */
}

@media screen and (max-width: 768px) {
    .search-version-card{
        margin-left: 2rem; /* 20px */
        margin-top: 0;
    }

    .single-result{
        background-color: #d9d9d92e;
        margin-bottom: 1rem; /* 10px */
        border-radius: 0.5rem; /* 5px */
    }
}