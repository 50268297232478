@font-face {
    font-family: "icons";
    font-display: swap;
    src: url("../fonts/icons/icons.eot?a709bcfeb3770a9455d1d08353988c44?#iefix") format("embedded-opentype"),
url("../fonts/icons/icons.woff2?a709bcfeb3770a9455d1d08353988c44") format("woff2"),
url("../fonts/icons/icons.woff?a709bcfeb3770a9455d1d08353988c44") format("woff"),
url("../fonts/icons/icons.ttf?a709bcfeb3770a9455d1d08353988c44") format("truetype"),
url("../fonts/icons/icons.svg?a709bcfeb3770a9455d1d08353988c44#icons") format("svg");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    /* font-weight: normal !important;
    font-variant: normal;
    text-transform: none; */
    line-height: 1;
    /* -webkit-font-smoothing: antialiased; */
    /* -moz-osx-font-smoothing: grayscale; */
    padding: 0rem 1rem 0rem 0rem; /* 0px 10px 0px 0px */
    font-size: 1.5rem; /* 15px */
    position: relative;
    top: 0.21rem; /* 2.1px */
}

.icon-search-icon:before {
    content: "\f101";
}
.icon-search-solid:before {
    content: "\f102";
}
.icon-up-arrow:before {
    content: "\f103";
}
.icon-user:before {
    content: "\f104";
}
