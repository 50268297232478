.search-form .form-group {
    float: right !important;
    transition: all 0.35s, border-radius 0s;
    width: 3.2rem; /* 32px */
    height: 3.2rem; /* 32px */
    background-color: #fff;
    box-shadow: 0 0.1rem 0.1rem /* 1px 1px */ rgba(0, 0, 0, 0.075) inset;
    border-radius: 2.5rem; /* 25px */
    border: 0.1rem /* 1px */ solid #ccc;
}

.search-form .form-group input.form-control {
    padding-right: 2rem; /* 20px */
    border: 0 none;
    background: transparent;
    box-shadow: none;
    display: block;
    width: -webkit-fill-available;
}

.search-form .form-group input.form-control::-webkit-input-placeholder {
    display: none;
}

.search-form .form-group input.form-control:-moz-placeholder {
    /* Firefox 18- */
    display: none;
}

.search-form .form-group input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    display: none;
}

.search-form .form-group input.form-control:-ms-input-placeholder {
    display: none;
}

.search-form .form-group:hover,
.search-form .form-group.hover {
    width: 100%;
    border-radius: 0.4rem 2.5rem 2.5rem 0.4rem; /* 4px 25px 25px 4px */
}

.search-form .form-group span.form-control-feedback {
    position: absolute;
    top: -0.1rem; /* -1px */
    right: -0.2rem; /* -2px */
    z-index: 2;
    display: block;
    width: 3.4rem; /* 34px */
    height: 3.4rem; /* 34px */
    line-height: 3.4rem; /* 34px */
    text-align: center;
    color: #3596e0;
    left: initial;
    font-size: 1.4rem; /* 14px */
}
nav.navbar.bootsnav {
    border-bottom: none;
}


nav.navbar.bootsnav ul.nav>li>a {
    padding: 1.9rem 1.5rem; /* 19px 15px */
}
nav.navbar .navbar-brand img.logo {
    top: 1.5rem; /* 15px */
}

.navbar-brand {
    padding: 0rem; /* 0px */
}

.nav-link {
    /* font-size: 1.2rem; */
    font-size: 1.92rem; /* 19.2px */
}

.navbar{
    margin-bottom: 0;
    border-radius: 0;
}

.logo {
    height: 2rem; /* 30px */
    margin: 0rem /* 0px */ !important;
    padding: 0rem /* 0px */ !important;
}

.nav-link {
    /* font-size: 1.2rem; */
    font-size: 1.92rem; /* 19.2px */
}

.navbar{
    margin-bottom: 0;
}

.navbar-toggler {
    /* font-size: 0.75rem; */
    font-size: 1.2rem; /* 12px */
}
.product-navbar{
    margin-left: 3rem /* 30px */ !important;
    padding:0rem 0rem 0rem 0rem; /* 0px 0px 0px 0px */
    margin-bottom: -0.5rem; /* -5px */
    margin-top: 0.5rem; /* 5px */
}

.header-links{
    color: #000000;
    font-size: '1.3rem' /* small === 13px */;
}

#responsive-navbar-nav {
    background: white;
    position: absolute;
    /* top: 3.4rem; */
    top: 5.44rem; /* 54.4px */
    right: 0;
    left: 0;
    height:95vh;
    overflow-y: scroll;
    padding-bottom: 3rem; /* 30px */
  }

  
@media screen and (max-width: 768px){
    .header-links{
        font-size: 1.4rem; /* 14px */
    }
}