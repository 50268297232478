

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;1,100&display=swap');
body {
    background: #ffffff;
    font-family: 'Noto Sans' !important;
    font-size: 1.5rem; /* 15px */
    color: #636e7b;
    font-weight: 200;
}

section {
    padding: 7rem 0rem 6rem 0rem; /* 70px 0px 60px 0px */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #535876;
    margin: 0 0 1.5rem 0; /* 15px */
    letter-spacing: 0.1rem; /* 1px */
    font-weight: 300;
    /* font-family: 'Avenir;' */
}

.dark {
    background: #f4f5f7;
}

.form-control {
    border: 0.1rem /* 1px */ solid #ccc;
    border-radius: 0.2rem; /* 2px */
    height: 4.5rem; /* 45px */
    margin-bottom: 1rem; /* 10px */
    box-shadow: none;
    font-size: 1.4rem; /* 14px */
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #da0833;
    border-color: #da0833;
    text-transform: uppercase;
    border-radius: 0.2rem; /* 2px */
    box-shadow: none;
    padding: 1rem 2.5rem; /* 10px 25px */
}

.form-control::-moz-placeholder {
    color: #8796a9;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #8796a9
}

.form-control::-webkit-input-placeholder {
    color: #8796a9
}

/*---------------Navigation Start-----------------*/
nav.navbar.bootsnav ul.nav>li>a {
    color: #6b797c;
    font-weight: 400;
    font-size: 1.42rem; /* 14.2px */
    /* font-family: 'Avenir'; */
    background-color: transparent !important;
}

nav.navbar.bootsnav ul.nav>li>a:hover {
    color: #FCCE30;
}

nav.navbar.bootsnav ul.nav>li.active>a {
    color: #FCCE30;
}

@media (min-width: 1024px) {
    nav.navbar li.dropdown ul.dropdown-menu {
        border-top: solid 0.5rem /* 5px */ #FCCE30 !important;
    }
}

/*-------------------Banner Start-----------------------*/
.main-banner {
    background-size: cover !important;
    background-position: center !important;
    position: relative;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 7rem 0rem 13rem 0rem; /* 70px 0px 130px 0px */
}

.main-banner .caption {
    position: relative;
    /* top:5.5rem; */
    top: 8.8rem; /* 88px */
}

.main-banner h2 {
    color: #ffffff;
    font-weight: 400;
    display: block;
    margin-bottom: 2.5rem; /* 25px */
}

.main-banner:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: #18032C;
    background-repeat: no-repeat;
    background-size: cover;
}

fieldset {
    background: rgba(255, 255, 255, 0.3);
    padding: 2rem 2rem 1rem 2rem; /* 20px 20px 10px 20px */
}

fieldset .no-pad {
    margin: 0;
    padding: 0.5rem; /* 5px */
}

.main-banner fieldset select.selectpicker,
.main-banner fieldset .form-control,
.main-banner fieldset .seub-btn {
    width: 100%;
    color: #6b797c;
    padding: 1.9rem 1.5rem; /* 19px 15px */
    border: none;
    border-radius: 0;
    height: auto;
    line-height: 1.5;
    font-size: 1.6rem; /* 16px */
    height: 6rem; /* 60px */
}

.main-banner fieldset select.selectpicker:focus,
.main-banner fieldset .form-control:focus,
.main-banner fieldset .seub-btn:focus {
    outline: none;
}

.main-banner fieldset .border-right {
    border-right: 0.1rem /* 1px */ solid rgba(107, 121, 124, 0.3) !important;
}

.main-banner .btn.seub-btn {
    background: #009DFF;
    color: #ffffff !important;
    text-transform: uppercase;
    font-size: '1.3rem' /* small === 13px */;
    text-shadow: none;
    line-height: 1.2;
}
.main-banner .btn.seub-btn:hover {
    background: #43474C;
}
/*--------------------Default Page Header-----------------------*/
.jumbotron {
    background: url(/img/background.jpg);
    background-size: cover;
    background-position: center;
    padding: 13% 0;
    position: relative;
    text-align: center;
    color: #ffffff;
    margin: 0;
}

.jumbotron:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: rgba(12, 20, 25, 0.5);
}

.user-img {
    max-width: 22rem; /* 220px */
    position: relative;
    margin: 0 auto;
}

.jumbotron img {
    display: table;
    max-width: 20rem; /* 200px */
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    border: 0.6rem /* 6px */ solid rgba(255, 255, 255, 0.8);
}

.caption.bott {
    margin-bottom: 8rem; /* 80px */
}

.notify {
    position: relative;
    margin-top: -0.5rem; /* -5px */
}

.notify .ring {
    position: absolute;
    top: -2rem; /* -20px */
    left: 5rem; /* 50px */
    height: 2.5rem; /* 25px */
    width: 2.5rem; /* 25px */
    z-index: 10;
    border: 0.5rem /* 5px */ solid #da0833;
    border-radius: 100%;
    -moz-animation: ring 1s ease-in;
    -moz-animation-iteration-count: infinite;
    -o-animation: ring 1s ease-in;
    -o-animation-iteration-count: infinite;
    -webkit-animation: ring 1s ease-in;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.notify .ring-point {
    width: 0.6rem; /* 6px */
    height: 0.6rem; /* 6px */
    -webkit-border-radius: 3rem; /* 30px */
    -moz-border-radius: 3rem; /* 30px */
    border-radius: 100%;
    background-color: #da0833;
    position: absolute;
    left: 5.9rem; /* 59px */
    top: -1rem;
}

@-moz-keyframes ring {
    0% /* -10px */ {
        -moz-transform: scale(0);
        opacity: 0
    }

    25% {
        -moz-transform: scale(.1);
        opacity: .1
    }

    50% {
        -moz-transform: scale(.5);
        opacity: .3
    }

    75% {
        -moz-transform: scale(.8);
        opacity: .5
    }

    100% {
        -moz-transform: scale(1);
        opacity: 0
    }
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: scale(0);
        opacity: 0
    }

    25% {
        -webkit-transform: scale(.1);
        opacity: .1
    }

    50% {
        -webkit-transform: scale(.5);
        opacity: .3
    }

    75% {
        -webkit-transform: scale(.8);
        opacity: .5
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0
    }
}

.jumbotron h4 {
    font-size: 2.8rem; /* 28px */
    margin-top: 1.2rem; /* 12px */
    margin-bottom: 1rem; /* 10px */
    position: relative;
    color: #ffffff;
}

.jumbotron p {
    font-size: 1.7rem; /* 17px */
    position: relative;
}

.jumbotron p span {
    font-size: 1.4rem; /* 14px */
    color: #13ad00;
}

.basic-information {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 3rem 3rem 2rem; /* 30px 30px 20px */
    -webkit-box-shadow: 0 0.5rem 2.5rem 0 /* 5px 25px */ rgba(0, 0, 0, .07);
    box-shadow: 0 0.5rem 2.5rem 0 /* 5px 25px */ rgba(0, 0, 0, .07);
    border: 0.1rem /* 1px */ solid #eaecf1;
    display: table;
    width: 100%;
}

.profile-detail img {
    border: 0.4rem /* 4px */ solid rgba(107, 121, 124, 0.2);
}

.basic-information h2 {
    margin: 0;
    margin-bottom: 1rem; /* 10px */
    color: #404b4d;
}

.basic-information h2 span {
    color: #da0833;
    font-size: 1.55rem; /* 15.5px */
    display: block;
}

.basic-information p {
    font-size: 1.7rem; /* 17px */
}

.information {
    margin: 0;
    padding: 0;
}

.information li {
    display: block;
    padding: 1rem 0; /* 10px */
    font-size: 15.5;
    list-style: none;
    border-bottom: 0.1rem /* 1px */ solid rgba(107, 121, 124, 0.1);
}

.information li span {
    display: inline-block;
    font-weight: bold;
    width: 15rem; /* 150px */
}

.social {
    margin: 0;
    padding: 5rem 0; /* 50px */
    display: inline-block;
    width: 100%;
}

.social li {
    list-style: none;
    float: left;
    margin-bottom: 0.7rem; /* 7px */
}

.social li a {
    font-size: 1.5rem; /* 15px */
    color: #6b797c;
}

.social li:hover a,
.social li:focus a {
    text-decoration: none;
}

a.facebook {
    border: 0.1rem /* 1px */ solid #295396;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

a.google {
    border: 0.1rem /* 1px */ solid #d6201f;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

a.twitter {
    border: 0.1rem /* 1px */ solid #1da0f2;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

a.linkedin {
    border: 0.1rem /* 1px */ solid #0077b5;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

a.instagram {
    border: 0.1rem /* 1px */ solid #d3368c;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

.social li i {
    width: 4.2rem; /* 42px */
    height: 4.2rem; /* 42px */
    border-radius: 50%;
    color: #ffffff;
    line-height: 4.2rem; /* 42px */
    text-align: center;
    margin-right: 0.7rem; /* 7px */
}

.social li .facebook i {
    background: #295396;
}

.social li .google i {
    background: #d6201f;
}

.social li .twitter i {
    background: #1da0f2;
}

.social li .linkedin i {
    background: #0077b5;
}

.social li .instagram i {
    background: #d3368c;
    ;
}

.panel {
    -webkit-box-shadow: 0 0.1rem 0.2rem /* 1px 2px */ rgba(0, 0, 0, .05);
    box-shadow: none;
    border: none;
}

.panel-default>.panel-heading {
    background: #f4f5f7;
    box-shadow: none;
    border: none;
    color: #505a5d;
}

.basic-information p {
    font-size: 1.6rem; /* 16px */
    line-height: 1.9;
}

.service-tag {
    background-color: #da0833;
    border-radius: 0 0.2rem 0.2rem 0; /* 2px 2px */
    color: #fff;
    display: inline-block;
    font-size: 1.1rem; /* 11px */
    font-weight: 700;
    line-height: 1.2727272727;
    margin: 0.2rem 0.4rem 0.2rem 1rem; /* 2px 4px 2px 10px */
    padding: 0.3rem 0.7rem; /* 3px 7px */
    position: relative;
    text-transform: uppercase;
}

.service-tag:after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 0.4rem; /* 4px */
    position: absolute;
    top: 0.8rem; /* 8px */
    left: -0.2rem; /* -2px */
    width: 0.4rem; /* 4px */
}

.service-tag:before {
    border-top: 1rem /* 10px */ solid transparent;
    border-right: 0.8rem /* 8px */ solid #da0833;
    border-bottom: 1rem /* 10px */ solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: 0;
    left: -0.8rem; /* -8px */
    width: 0;
}

.panel-body ul {
    margin: 0;
    padding: 0;
    margin-top: 2rem; /* 20px */
}

.panel-body ul li {
    list-style: none;
    line-height: 1.7;
    padding: 0 0 0.6rem 1rem; /* 6px 10px */
    margin: 1rem 0; /* 10px */
    margin-left: 1.6rem; /* 16px */
}

.panel-body ul li:before {
    display: inline-block;
    font: normal normal normal 1.4rem/1 /* 14px */ FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: "\f067";
    color: #da0833;
    padding: 0.8rem 1.2rem 0.8rem 0.5rem; /* 8px 12px 8px 5px */
    margin-left: -3.1rem; /* -31px */
}

/*----------------------------Features Style-------------------------*/
.features-content {
    border: 0.1rem /* 1px */ solid #f4f5f7;
    padding: 2rem 1.5rem; /* 20px 15px */
    text-align: center;
    border-radius: 0.5rem; /* 5px */
    margin-bottom: 3rem; /* 30px */
    box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
}

.features-content h3 {
    font-weight: 400;
    color: #475052;
    font-size: 1.8rem; /* 18px */
    text-transform: uppercase;
    margin-bottom: 0;
}


.features-content p {
    font-weight: 300;
    color: #6b797c;
    line-height: 1.8;
    font-size: 1.4rem; /* 14px */
}

.features-content span {
    font-size: 4.5rem; /* 45px */
    margin-bottom: 2.5rem; /* 25px */
    color: #FCCE30;
}

/*--------------------Style for Browse Job----------------------*/
.jobs .row {
    margin: 0;
}

.heading {
    text-align: center;
    margin-bottom: 3rem; /* 30px */
}

.heading h2 {
    font-weight: 400;
    margin-bottom: 1rem; /* 10px */
}

.heading p {
    font-weight: 300;
    font-size: 1.7rem; /* 17px */
}

.companies {
    margin-top: 3rem; /* 30px */
}

.company-logo {
    display: table;
    width: 100%;
}

.company-logo img {
    margin: 0 auto;
    max-width: 10rem /* 100px */
}

.company-content {}

.company-content h3 {
    font-size: 2.5rem; /* 25px */
    font-weight: 400;
    margin-bottom: 1rem; /* 10px */
    margin-top: 0.7rem; /* 7px */
}

.company-content h3 span {
    font-size: 1.3rem; /* 13px */
    padding: 0.2rem 2rem; /* 2px 20px */
    float: right;
    text-align: center;
    color: #ffffff;
    margin-top: 0.5rem; /* 5px */
    display: inline-block;
    line-height: 1.7;
    border-radius: 0.2rem; /* 2px */
}

.company-content p span {
    font-size: 1.5rem; /* 15px */
    margin-right: 2rem; /* 20px */
    margin-bottom: 1rem; /* 10px */
}

.company-content p span i {
    font-size: 1.5rem; /* 15px */
    margin-right: 0.5rem; /* 5px */
}

.company-list {
    background: #ffffff;
    margin: 1.2rem 0; /* 12px */
    padding: 1.2rem 0 1.2rem 0; /* 12px 12px */
    border: 0.1rem /* 1px */ solid #f4f5f7;
    -webkit-box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
    box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
}

.company-content h3 .full-time {
    background: #1ec715;
}

.company-content h3 .freelance {
    background: #01b5ff;
}

.company-content h3 .internship {
    background: #cc68f3;
}

.company-content h3 .part-time {
    background: #ff8f3e;
}

.brows-btn {
    background: #da0833;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.6rem; /* 16px */
    display: table;
    text-shadow: none;
    line-height: 1.2;
    margin: 2rem /* 20px */ auto;
    font-weight: 400;
    padding: 1.4rem 2.5rem; /* 14px 25px */
}

/*--------------------Style for Job Filter----------------------*/
.jobs {
    padding: 6rem 0; /* 60px */
}

.jobs .row {
    margin: 0;
}

.heading {
    text-align: center;
    margin-bottom: 3rem; /* 30px */
}

.heading h2 {
    font-weight: 400;
    margin-bottom: 1rem; /* 10px */
}

.heading p {
    font-weight: 300;
    font-size: 2rem; /* 20px */
}

.companies {
    margin-top: 3rem; /* 30px */
}

.company-logo {
    display: table;
    width: 100%;
}

.company-logo img {
    margin: 0 auto;
    max-width: 10rem /* 100px */
}

.company-content {}

.company-content h3 {
    font-size: 2.5rem; /* 25px */
    font-weight: 400;
    margin-bottom: 1rem; /* 10px */
    margin-top: 0.7rem; /* 7px */
}

.company-content h3 span {
    font-size: 1.3rem; /* 13px */
    padding: 0.2rem 2rem; /* 2px 20px */
    float: right;
    text-align: center;
    color: #ffffff;
    margin-top: 0.5rem; /* 5px */
    display: inline-block;
    line-height: 1.7;
    border-radius: 0.2rem; /* 2px */
}

.company-content p span {
    font-size: 1.5rem; /* 15px */
    margin-right: 2rem; /* 20px */
    margin-bottom: 1rem; /* 10px */
}

.company-content p span i {
    font-size: 1.5rem; /* 15px */
    margin-right: 0.5rem; /* 5px */
}

.company-list {
    background: #ffffff;
    margin: 1.2rem 0; /* 12px */
    padding: 1.2rem 0 1.2rem 0; /* 12px 12px */
    border: 0.1rem /* 1px */ solid rgba(107, 121, 124, 0.3);
    -webkit-box-shadow: 0 0.5rem 2.5rem 0 /* 5px 25px */ rgba(0, 0, 0, .07);
    box-shadow: 0 0.5rem 2.5rem 0 /* 5px 25px */ rgba(0, 0, 0, .07);
}

.brows-btn {
    background: #da0833;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.6rem; /* 16px */
    display: table;
    text-shadow: none;
    line-height: 1.2;
    margin: 2rem /* 20px */ auto;
    font-weight: 400;
    padding: 1.4rem 2.5rem; /* 14px 25px */
}

.btn.view-job {
    border: none;
    background: #007ffe;
    border-radius: 0.1rem; /* 1px */
    padding: 1em 2em;
    display: block;
    margin-top: 2.5rem; /* 25px */
    width: 100%;
    color: #ffffff;
}

.top-pad {
    padding-top: 3em;
}

.filter-list {
    margin: 0;
    padding: 0;
}

.filter-list li {
    margin: 0;
    padding: 0;
    font-weight: 300;
    list-style: none;
    padding-left: 1rem; /* 10px */
    display: inline-block;
}

.filter-list label {
    font-weight: normal;
    margin: 0 0.5rem; /* 5px */
}

.checkbox-custom,
.radio-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 0.5rem; /* 5px */
    cursor: pointerer;
}

.checkbox-custom-label,
.radio-custom-label {
    position: relative;
}

.checkbox-custom+.checkbox-custom-label:before,
.radio-custom+.radio-custom-label:before {
    content: '';
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 2rem; /* 20px */
    height: 2rem; /* 20px */
    padding: 0.2rem; /* 2px */
    border-radius: 50%;
    margin-right: 1rem; /* 10px */
    text-align: center;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
    background: #ef193c;
    box-shadow: inset 0rem 0rem 0rem 0.4rem /* 0px 0px 0px 4px */ #fff;
}

.radio-custom+.radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked+.radio-custom-label:before {
    background: #ef193c;
    box-shadow: inset 0rem 0rem 0rem 0.4rem /* 0px 0px 0px 4px */ #fff;
}

.company-brands label {
    font-weight: normal;
    color: #828f99;
    font-size: 1.5rem; /* 15px */
}

.checkbox-custom:focus+.checkbox-custom-label,
.radio-custom:focus+.radio-custom-label {
    outline: none
        /* focus style */
}

.company-brands input[type='checkbox']:focus {
    outline: none;
}

.company-brands label:focus {
    outline: none;
}

.checkbox-custom+.part-time.checkbox-custom-label:before,
.radio-custom+.part-time.radio-custom-label:before {
    border: 0.2rem /* 2px */ solid #0080ff;
}

.checkbox-custom:checked+.part-time.checkbox-custom-label:before {
    background: #0080ff;
}

.checkbox-custom+.full-time.checkbox-custom-label:before,
.radio-custom+.full-time.radio-custom-label:before {
    border: 0.2rem /* 2px */ solid #40af37;
}

.checkbox-custom:checked+.full-time.checkbox-custom-label:before {
    background: #40af37;
}

.checkbox-custom+.freelancing.checkbox-custom-label:before,
.radio-custom+.freelancing.radio-custom-label:before {
    border: 0.2rem /* 2px */ solid #0080ff;
}

.checkbox-custom:checked+.freelancing.checkbox-custom-label:before {
    background: #0080ff;
}

.checkbox-custom+.internship.checkbox-custom-label:before,
.radio-custom+.internship.radio-custom-label:before {
    border: 0.2rem /* 2px */ solid #ef183c;
}

.checkbox-custom:checked+.internship.checkbox-custom-label:before {
    background: #ef183c;
}

/*-----------------------counter Section start-------------------------*/
.counter {
    background: #da0833;
    padding: 6rem 0; /* 60px */
}

.counter-text {
    text-align: center;
    color: #ffffff;
}

.counter-text h3 {
    color: #ffffff;
    font-weight: 400;
    font-size: 3.2rem; /* 32px */
    margin-bottom: 0.5rem; /* 5px */
}

.counter-text p {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.7rem; /* 17px */
    text-transform: uppercase;
    margin-bottom: 0;
}

.counter-text span {
    font-size: 3.5rem; /* 35px */
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 1.5rem; /* 15px */
}

/*--------------------Testimonial Start------------------------*/
.testimonials {
    background: #f4f5f7;
    padding: 6rem 0; /* 60px */
}

.testimonial {
    text-align: center;
    margin: 5rem 1rem 0; /* 50px 10px */

}

.testimonial .pic {
    width: 10rem; /* 100px */
    height: 10rem; /* 100px */
    border-radius: 50%;
    border: 0.5rem /* 5px */ solid rgba(255, 255, 255, 0.3);
    display: inline-block;
    margin-top: -5rem; /* -50px */
    overflow: hidden;
    box-shadow: 0 0.2rem 0.6rem /* 2px 6px */ rgba(0, 0, 0, 0.15);
}

.testimonial .pic img {
    width: 100%;
    height: auto;
}

.testimonial .description {
    font-size: 2.2rem; /* 22px */
    line-height: 3.4rem; /* 34px */
    margin: 1rem 0 2rem; /* 10px 20px */
    /* font-family: 'Avenir'; */
    font-style: italic;
}

.testimonial .testimonial-title {
    font-size: 1.4rem; /* 14px */
    font-weight: bold;
    margin: 0;
    color: #da0833;
    text-transform: uppercase;
}

.testimonial .post {
    display: block;
    font-size: 1.3rem; /* 13px */
    color: #777;
    text-transform: capitalize;
}

.owl-theme .owl-controls {
    margin-top: 1rem; /* 10px */
}

.owl-theme .owl-controls .owl-pagination {
    width: 14rem; /* 140px */
    padding: 1rem; /* 10px */
    margin: 0 auto;
    line-height: 1.3rem; /* 13px */
}

.owl-theme .owl-controls .owl-page span {
    width: 1.2rem; /* 12px */
    height: 1.2rem; /* 12px */
    border-radius: 50%;
    background: transparent;
    border: 0.1rem /* 1px */ solid #6b797c;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    border: 0.4rem /* 4px */ solid #da0833;
}

/*------------------package section start---------------*/
.package-box {
    background: #ffffff;
    border-radius: 0.6rem 0.6rem 0rem 0rem; /* 6px 6px 0px 0px */
    overflow: hidden;
    margin-bottom: 3rem; /* 30px */
    text-align: center;
    padding: 0rem 1rem 3rem 1rem; /* 0px 10px 30px 10px */
    border: 0.1rem /* 1px */ solid #f4f5f7;
    -webkit-box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
    box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
}

.package-header {
    padding: 2rem 0; /* 20px */
}

.package-header i {
    font-size: 3em;

}

.package-header h3 {
    text-transform: uppercase;
    color: #242c36;
    font-size: 2rem; /* 20px */
    font-weight: 600;
}

.package-info ul {
    padding: 0 1.5rem; /* 15px */
    margin: 0;
}

.package-info ul li {
    padding: 1rem 0; /* 10px */
    list-style: none;
    border-bottom: 0.1rem /* 1px */ solid #f4f5f7;
}

.package-price {
    padding: 2rem 0; /* 20px */
}

.package-price h2 {
    color: #242c36;
    font-weight: 600;
}

.package-price h2 sup,
.package-price h2 sub {
    font-size: 2rem; /* 20px */
    opacity: 0.7;
}

button.btn.btn-package {
    background: #da0833;
    color: #ffffff;
    text-transform: uppercase;
    padding: 1rem 3.2rem; /* 10px 32px */
    border-radius: 5rem; /* 50px */
}

button.btn.btn-package:hover,
button.btn.btn-package:focus {
    background: #242c36;
}

/*---------------------Member Card---------------*/
.card-deck {
    margin: 0.5rem /* 5px */ !important;
}

.card {
    padding-bottom: 0.5rem /* 5px */ !important;
}

.card-link {
    color: #18032d !important;
    padding-bottom: 0rem /* 0px */ !important;
}
.card-widget {
    background-color: #fff;
    border-color: #d7dfe3;
    border: none;
    -webkit-box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
    box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
    border-radius: 0.6rem; /* 6px */
    text-align: center;
    overflow: hidden;
    transition: .7s;
    margin-bottom: 3rem; /* 30px */
}

.user-card .u-img {
    margin-bottom: -4.2rem; /* -42px */
    height: 15rem; /* 150px */
}

.user-card .u-content {
    /* padding: 0 1.25rem 1.25rem; */
    padding: 0 2rem 2rem; /* 20px 20px */
}

.user-card .avatar {
    /* margin: 0 auto 2rem auto; */
    margin: 0 auto 3.2rem /* 32px */ auto;
    position: relative;
    display: block;
    max-width: 8.5rem; /* 85px */
}

.avatar.box-80 i {
    position: absolute;
    bottom: -0.6rem; /* -6px */
    left: 3.7rem; /* 37px */
    color: #07b107;
}

.u-content h5 {
    font-size: 1.8rem; /* 18px */
    color: #242c36;
    margin-bottom: 0.5rem; /* 5px */
}

.text-muted {
    margin-bottom: 0.5rem; /* 5px */
}

.user-social-profile {
    padding: 0.5rem 0 2.5rem 0; /* 5px 25px */
}

.user-social-profile ul {
    padding: 0;
    margin: 0;
}

.user-social-profile ul li {
    list-style: none;
    display: inline-block;
    padding: 0 0.4rem; /* 4px */
}

.user-social-profile ul li a i {
    background: rgba(99, 110, 123, 0.1);
    width: 4.2rem; /* 42px */
    height: 4.2rem; /* 42px */
    line-height: 4.2rem; /* 42px */
    font-size: 1.7rem; /* 17px */
    border-radius: 50%;
    color: #636e7b;
}

.user-social-profile ul li a i:hover,
.user-social-profile ul li a i:focus {
    background: #da0833;
    color: #ffffff;
}

/*----------------Newsletter Style----------------*/
.newsletter {
    text-align: center;
}

.newsletter h2 {
    font-weight: 500;
    margin-bottom: 0.5rem; /* 5px */
}

.newsletter p {
    font-size: 2.3rem; /* 23px */
    /* font-family: 'Avenir'; */
    font-style: italic;
}

.newsletter input.form-control {
    border: 0.1rem /* 1px */ solid #e3e6ea;
    box-shadow: none;
}

.newsletter button.btn.btn-default {
    background: #da0833;
    text-transform: uppercase;
    border-color: #da0833;
    color: #ffffff;
}

/*------login style---*/
section.login-wrapper {
    text-align: center;
}

section.login-wrapper p a {
    color: #da0833;
}

section.login-wrapper p {
    margin-top: 2rem; /* 20px */
}

section.login-wrapper label,
section.login-wrapper label a {
    font-weight: 400;
    display: block;
    text-align: left;
    color: #636e7b;
}

section.login-wrapper img {
    margin: 2rem /* 20px */ auto;
}

/*-------------------Inner page Start-----------------------*/
.inner-banner {
    background-size: cover !important;
    background-position: center !important;
    padding: 7% 0;
    position: relative;
    text-align: center;
    color: #ffffff;
    margin: 0;
}

.inner-banner .caption {
    position: relative;
}

.caption  h2{
    font-size: 3.6rem /* 36px */ !important;
    line-height: 3.2rem; /* 32px */
    text-transform:uppercase;
}

.caption  h5{
    font-size: 2.4rem /* 24px */ !important;
    line-height: auto;
    color:#ffffff;
}

.inner-banner h2 {
    color: #ffffff;
    font-weight: 400;
    display: block;
    margin-bottom: 2.5rem; /* 25px */
}

.inner-banner p {
    color: #ffffff;
    font-weight: 300;
    display: block;
    font-size: 2rem; /* 20px */
    margin-top: 1.5rem; /* 15px */
}

.inner-banner p span {
    color: #da0833;
}

.inner-banner:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: rgba(12, 20, 25, 0.7);
}

@media only screen and (max-width: 768px) {
    .caption h2 {
        font-size: 2.4rem /* 24px */ !important;
        letter-spacing: 0.4rem; /* 4px */
        line-height: auto;
    }
}


.copy-right {
    background: #18032d;
    padding: 1.2rem 0;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
  }
  
  .copy-right a {
    margin-left: 1.8rem;
    color: #FFFFFF;
    margin-right: 1.5rem;
    font-size: 1.3rem;
    text-decoration: none !important;
  }

footer img {
    margin-bottom: 2rem; /* 20px */
}

footer h4 {
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-size: 2rem; /* 20px */
    font-weight: 400;
}

footer ul {
    margin: 0;
    padding: 0;
}

footer ul li {
    list-style: none;
}

footer ul li a {
    color: #636e7b;
    line-height: 2;
}

footer ul li a:hover {
    color: #da0833;
}

footer address ul li {
    margin-bottom: 1rem; /* 10px */
    line-height: 1.8;
}

footer .form-control {
    border: 0.1rem /* 1px */ solid #3f4a58;
    margin-bottom: 0.7rem; /* 7px */
    border-radius: 0.2rem; /* 2px */
    box-shadow: none;
    color: #8796a9;
    height: 4.5rem; /* 45px */
    font-size: 1.4rem; /* 14px */
    background: #333d4a;
}

footer textarea.form-control {
    height: 7rem; /* 70px */
}

footer button.btn.btn-primary {
    border-radius: 0.2rem; /* 2px */
    min-width: 12rem; /* 120px */
    padding: 1rem 0; /* 10px */
    text-align: center;
    text-transform: uppercase;
    background: #da0833;
    border: 0.1rem /* 1px */ solid #da0833;
}

footer .form-control::-moz-placeholder {
    color: #8796a9;
    opacity: 1
}

footer .form-control:-ms-input-placeholder {
    color: #8796a9
}

footer .form-control::-webkit-input-placeholder {
    color: #8796a9
}

/*---------------------------responsive start-----------------------*/
@media only screen and (max-width: 767px) {
    .social li {
        width: 100%;
    }

    .main-banner select {
        margin-bottom: 1rem; /* 10px */
    }

    .company-content p span {
        display: block;
    }

    .input-group {
        width: 100%;
        display: block;
    }

    .newsletter button.btn.btn-default {
        margin: 0.5rem 0; /* 5px */
        width: 100%;
        height: 4.2rem; /* 42px */
        border-radius: 0.2rem; /* 2px */
    }

    .input-group-addon,
    .input-group-btn {
        display: table;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .inner-banner h2 {
        margin-bottom: 0.2rem; /* 2px */
    }

    .inner-banner p {
        margin-top: 0.2rem; /* 2px */
    }

    .basic-information p {
        font-size: 1.4rem; /* 14px */
        line-height: 1.9;
    }
}

@media only screen and (min-width: 768px) {

    .main-banner h2 {
        font-size: 5rem; /* 50px */
    }

    .inner-banner h2 {
        font-size: 8.2rem; /* 82px */
    }

    .social li {
        width: 20%;
    }

    .newsletter button.btn.btn-default,
    .newsletter input.form-control {
        height: 6rem; /* 60px */
        padding: 0 2rem; /* 20px */
    }

    .md-right{
        text-align: right;
        right: 5.5rem; /* 55px */
    }

    .md-left{
        text-align: left;
        margin-left: 5.5rem; /* 55px */
    }
}

