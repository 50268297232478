
.modalContent {
    margin-left: 3rem; /* 30px */
    margin-right: 3rem; /* 30px */
    text-align: justify;
    text-justify: inter-word;
}

.modalTopic{
    font-color: black;
    font-weight: bold;
    font-size: 1.8rem; /* 18px */
    margin-bottom: 0.5rem; /* 5px */

}

.modalDescription{
    color: black;
    font-size: 1.6rem; /* 16px */
    margin-bottom: 2.5rem; /* 25px */
}
.modalExplanation {
    margin-bottom: 2.5rem; /* 25px */
    font-size: 1.6rem; /* 16px */
}

.modal-header {
    margin: 1rem 3rem 1rem 3rem; /* 10px 30px 10px 30px */
}

.modalExplanation span {
    color: unset !important;
}

.modalExplanation div strong {
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
}