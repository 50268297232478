#loader-wrapper,#ajax-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	z-index: 1006;
	display: flex;
	justify-content: center;
	align-items: center;
    background-color: #ffffff
}
#ajax-loader-wrapper{
    background-color: rgba(0, 0, 0, 0.6);
}

.loader-container{
	position: relative;
}

.loader-text{
	position: absolute;
	min-width: 40rem; /* 400px */
	left: 50%;
	top: 50%;
	margin: -17rem 0 0 -11rem; /* -170px -110px */
}

.loader-spinner{
	display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10rem; /* 100px */
    height: 10rem; /* 100px */
    margin: -5rem 0 0 -5rem; /* -50px -50px */
    border-radius: 50%;
    border: 0.3rem /* 3px */ solid transparent;
    border-top-color: #535876;
    -webkit-animation: spin 0.9s cubic-bezier(.14,.66,.88,.6) infinite; /* / Chrome, Opera 15+, Safari 5+ / */
    animation: spin 0.9s cubic-bezier(.14,.66,.88,.6) infinite; /* / Chrome, Firefox 16+, IE 10+, Opera / */
    z-index: 1001;
}

.icon-loader-spinner{
	display: block;
	position: absolute;
	left: 50%;
    top: 50%;
    width: 10rem; /* 100px */
	height: 10rem; /* 100px */
	margin: -4.7rem 0 0 -5rem; /* -47px -50px */
	border-radius: 50%;
    border: 0.3rem /* 3px */ solid transparent;
}

.loader-logo-container{
	display: block;
	position: absolute;
	left: 50%;
    top: 50%;
    width: 10rem; /* 100px */
	height: 10rem; /* 100px */
	margin: -2.7rem 0 0 -5rem; /* -47px -50px */
	border-radius: 50%;
    /* background-image: ".."; */
    border: 0.3rem /* 3px */ solid transparent;
}

.loader-logo-container img{
	width: 6.2rem; /* 62px */
	margin: 3rem 0 0 1.6rem; /* 17px 16px */
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}