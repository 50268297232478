/* 4k screen font scaling */

html {
    /* using 10px as root font size; all rem values scaled accordingly */
    font-size: 10px;
}

@media screen and (min-width: 2160px) {
    html {
        font-size: 20px;    /* fallback, incase calc does not work */
        /* calc to scale ui elements so that they maintain the size they have @2160px width */
        font-size: calc(100vw * 10 / 2160);
    }

    .issue-container .container {
        max-width: 125rem;
    }
}

/* other fixes */

@media (min-width: 1200px) {
    .container {
        max-width: 114rem /* 1140px */;
    }
}

/* prevent empty bar below footer */

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#root .content-wrapper {
    flex: 1 0;
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 114.0rem;
        margin: 4rem auto;
    }
}
