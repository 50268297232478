.ant-layout-header {
  background: #ffffff;
}

.detail-text {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entity-detail {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #1890ff !important;
  z-index: 2;
}

.ant-collapse {
  background-color: unset;
  border-bottom: 1px solid #d9d9d9;
}

.collapse-1 {
  border: unset;
}

.collapse-0 > .playbook-item {
  padding: 16px;
}

.tab-list {
  margin-top: 16px;
  background-color: rgb(255, 255, 255);
}

.ant-layout-content {
  background-color: unset;
}

.ant-page-header {
  padding: unset !important;
}

.ant-page-header-heading {
  padding: 16px 24px;
  padding-bottom: unset;
  position: fixed;
  z-index: 10;
  background-color: white;
  width: 100%;
}

.ant-tabs-nav {
  padding: 16px 24px;
  padding-bottom: unset;
  padding-top: unset;
  margin-top: 54px !important;
  position: fixed !important;
  z-index: 10;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

.ant-tabs-tabpane {
  padding: 12px 24px;
}

.ant-page-header-footer {
  margin-top: unset;
}

.ant-avatar > img {
  object-fit: contain !important;
}

.ant-tabs-content-holder {
  margin-top: 152px;
}

.input-container {
  display: flex;
  z-index: 10;
  position: fixed;
  width: 100%;
  padding: 12px 24px;
  background-color: white;
  margin-top: 96px;
  border-bottom: 1px solid #d9d9d9;
}

.ant-card {
  cursor: pointer;
}