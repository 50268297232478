@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,400;1,100&display=swap);
#loader-wrapper,#ajax-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	z-index: 1006;
	display: flex;
	justify-content: center;
	align-items: center;
    background-color: #ffffff
}
#ajax-loader-wrapper{
    background-color: rgba(0, 0, 0, 0.6);
}

.loader-container{
	position: relative;
}

.loader-text{
	position: absolute;
	min-width: 40rem; /* 400px */
	left: 50%;
	top: 50%;
	margin: -17rem 0 0 -11rem; /* -170px -110px */
}

.loader-spinner{
	display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10rem; /* 100px */
    height: 10rem; /* 100px */
    margin: -5rem 0 0 -5rem; /* -50px -50px */
    border-radius: 50%;
    border: 0.3rem /* 3px */ solid transparent;
    border-top-color: #535876; /* / Chrome, Opera 15+, Safari 5+ / */
    animation: spin 0.9s cubic-bezier(.14,.66,.88,.6) infinite; /* / Chrome, Firefox 16+, IE 10+, Opera / */
    z-index: 1001;
}

.icon-loader-spinner{
	display: block;
	position: absolute;
	left: 50%;
    top: 50%;
    width: 10rem; /* 100px */
	height: 10rem; /* 100px */
	margin: -4.7rem 0 0 -5rem; /* -47px -50px */
	border-radius: 50%;
    border: 0.3rem /* 3px */ solid transparent;
}

.loader-logo-container{
	display: block;
	position: absolute;
	left: 50%;
    top: 50%;
    width: 10rem; /* 100px */
	height: 10rem; /* 100px */
	margin: -2.7rem 0 0 -5rem; /* -47px -50px */
	border-radius: 50%;
    /* background-image: ".."; */
    border: 0.3rem /* 3px */ solid transparent;
}

.loader-logo-container img{
	width: 6.2rem; /* 62px */
	margin: 3rem 0 0 1.6rem; /* 17px 16px */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.search-form .form-group {
    float: right !important;
    transition: all 0.35s, border-radius 0s;
    width: 3.2rem; /* 32px */
    height: 3.2rem; /* 32px */
    background-color: #fff;
    box-shadow: 0 0.1rem 0.1rem /* 1px 1px */ rgba(0, 0, 0, 0.075) inset;
    border-radius: 2.5rem; /* 25px */
    border: 0.1rem /* 1px */ solid #ccc;
}

.search-form .form-group input.form-control {
    padding-right: 2rem; /* 20px */
    border: 0 none;
    background: transparent;
    box-shadow: none;
    display: block;
    width: -webkit-fill-available;
}

.search-form .form-group input.form-control::-webkit-input-placeholder {
    display: none;
}

.search-form .form-group input.form-control:-moz-placeholder {
    /* Firefox 18- */
    display: none;
}

.search-form .form-group input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    display: none;
}

.search-form .form-group input.form-control:-ms-input-placeholder {
    display: none;
}

.search-form .form-group:hover,
.search-form .form-group.hover {
    width: 100%;
    border-radius: 0.4rem 2.5rem 2.5rem 0.4rem; /* 4px 25px 25px 4px */
}

.search-form .form-group span.form-control-feedback {
    position: absolute;
    top: -0.1rem; /* -1px */
    right: -0.2rem; /* -2px */
    z-index: 2;
    display: block;
    width: 3.4rem; /* 34px */
    height: 3.4rem; /* 34px */
    line-height: 3.4rem; /* 34px */
    text-align: center;
    color: #3596e0;
    left: auto;
    left: initial;
    font-size: 1.4rem; /* 14px */
}
nav.navbar.bootsnav {
    border-bottom: none;
}


nav.navbar.bootsnav ul.nav>li>a {
    padding: 1.9rem 1.5rem; /* 19px 15px */
}
nav.navbar .navbar-brand img.logo {
    top: 1.5rem; /* 15px */
}

.navbar-brand {
    padding: 0rem; /* 0px */
}

.nav-link {
    /* font-size: 1.2rem; */
    font-size: 1.92rem; /* 19.2px */
}

.navbar{
    margin-bottom: 0;
    border-radius: 0;
}

.logo {
    height: 2rem; /* 30px */
    margin: 0rem /* 0px */ !important;
    padding: 0rem /* 0px */ !important;
}

.nav-link {
    /* font-size: 1.2rem; */
    font-size: 1.92rem; /* 19.2px */
}

.navbar{
    margin-bottom: 0;
}

.navbar-toggler {
    /* font-size: 0.75rem; */
    font-size: 1.2rem; /* 12px */
}
.product-navbar{
    margin-left: 3rem /* 30px */ !important;
    padding:0rem 0rem 0rem 0rem; /* 0px 0px 0px 0px */
    margin-bottom: -0.5rem; /* -5px */
    margin-top: 0.5rem; /* 5px */
}

.header-links{
    color: #000000;
    font-size: '1.3rem' /* small === 13px */;
}

#responsive-navbar-nav {
    background: white;
    position: absolute;
    /* top: 3.4rem; */
    top: 5.44rem; /* 54.4px */
    right: 0;
    left: 0;
    height:95vh;
    overflow-y: scroll;
    padding-bottom: 3rem; /* 30px */
  }

  
@media screen and (max-width: 768px){
    .header-links{
        font-size: 1.4rem; /* 14px */
    }
}
.product-dropdown .dropdown-menu {
    margin: 0.1rem 0 0 0; /* 1px */
    position: fixed !important;
    top: 9.8rem !important;
    left: 0 !important;
    transform: none !important;
    width: 100% !important;

    padding: 1rem 1.5rem 1rem 7rem /* 10px 15px 10px 70px */ !important;
    overflow: auto;
    max-height: 70% !important;
    font-size: 1.5rem; /* 15px */
    box-shadow: 0 10rem 10rem 0 /* 100px 100px */ rgba(0,0,0,.4) !important;
}


.dropdown-menu>li>a:hover {
background-color:transparent !important;
}

.btn-toolbar {
display: flex;
justify-content: flex-start;
}

.btn-toolbar>.btn, .btn-toolbar>.btn-group, .btn-toolbar>.input-group {
margin-left: 2rem; /* 20px */
}
.dropdown-item {
display: block;
width: 100%;
/* padding: .25rem 1.5rem; */
padding: 0.4rem 2.4rem; /* 4px 24px */
clear: both;
font-weight: 400;
color: #212529;
text-align: inherit;
white-space: nowrap;
background-color: transparent;
border: 0;
}

.dropdown-toggle:after {
display: none !important;
margin-left: .255em;
vertical-align: .255em;
content: "";
border-top: .3em solid;
border-right: .3em solid transparent;
border-bottom: 0;
border-left: .3em solid transparent;
}

.btn-primary, .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {
color:black!important;
background-color: transparent !important;
border-color: transparent !important;
text-transform: none !important;
box-shadow: none !important;
padding: 0rem 0rem /* 0px 0px */ !important;
/* font-size: 1.3rem; */
font-size: 2.08rem; /* 20.8px */
/* font-family: "Avenir"; */
    font-size: 1.5rem; /* 15px */
    border-bottom: 0.5rem /* 5px */ solid transparent !important;
    line-height: -0.1rem; /* -1px */
}

:focus{
    outline: white !important;    
}

.btn-primary:hover {
    /* color: #fbc715 !important; */
    background-color: transparent !important;
    /* border-radius: 2px; */
    box-shadow: none !important;
}

.product-dropdown-menu{
    padding-right: 5.5rem; /* 55px */
}

.product-dropdown{
    /* margin-bottom: 3px !important; */
    height:3rem /* 30px */ !important;
    transition: 0.1ms;;
}

.product-dropdown:hover{
    border-bottom: 0.4rem /* 4px */ solid #535876 !important;
}
.fixed-top, .sticky-top{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    /* z-index: 999; */
    transition: position .8s linear;
}


.navigation-search-input {
    box-shadow: none;
    font-size: 1.3rem; /* 13px */
    padding-top: 1.5rem; /* 15px */
    font-style: italic;
    border: none !important;
    width:100%;
}
.navigation-search-input:focus {
    box-shadow: none;
    font-size: 1.4rem; /* 14px */
    transition: all 0.4s;
  }
  
.navigation-search-underlined {
    border-width: 0;
    border-bottom-width: 0rem; /* 0px */
    border-radius: 0;
    padding-left: 0;
  }

.dropdown-divider {
    height: 1.1rem; /* 11px */
    margin: 0rem 0rem 0.2rem 0rem; /* 0px 0px 2px 0px */
    border-top: 0.1rem solid transparent;
}

.category{
    margin-left:0rem; /* 0px */
    color:#616161cc;
    /* margin-bottom: 2.5rem; */
    margin-bottom: 4rem; /* 40px */
    text-transform: uppercase;

    /* border-bottom: 1px solid gray; */
    /* text-transform: uppercase; */
}

.manuals{
    background:transparent;
    padding-left: 0rem; /* 0px */
    padding-right: 1rem; /* 10px */
}

.manual-info{
    color:#000000;
    font-size: 1.5rem; /* 15px */
}

.manuals:hover .manual-info{
    /* background: grey; */
    color:#009DFF !important;
}

.search-description{
    font-style: italic;
    font-size: 1.2rem; /* 12px */
    color: #A9A9A9;
    margin-left: 1.5rem; /* 15px */
}

.label-search-category{
    font-size: 1.2rem; /* 12px */
    border: 0.1rem /* 1px */ solid;
    background: #535876;
    color: white;
    padding: 0.3rem 1rem 0.3rem 1rem; /* 3px 10px 3px 10px */
    border-radius: 0.6rem; /* 6px */
    float: right;
    margin:-0.15rem 0rem 0rem 0rem; /* -1.5px 0px 0px 0px */
}


.menu-search-list{
    color: #18032d;
    padding:0.7rem 1.5rem 0.7rem 2rem;
 }
.menu-search-list:hover{
    color:#009DFF;
}

.Collapsible{
    margin: 0rem 0rem 0rem 3rem; /* 0px 0px 0px 30px */
    padding: 1rem 0rem 1rem 0rem; /* 10px 0px 10px 0px */
}

.Collapsible>span {
    text-transform: uppercase;
    letter-spacing: 0.3rem; /* 3px */
    color: #A9A9A9;
    /* font-size: 1rem; */
    font-size: 1.6rem; /* 16px */
    text-align: left;
    display: block;
    border-bottom: 0.1rem /* 1px */ solid #8080801f;
}

.Collapsible__trigger:after {
    content: '\2304';
        right: 1rem; /* 10px */
    top: 1rem; /* 10px */
    transition: transform 300ms;
    margin-right: 3rem; /* 30px */
    float: right;
}

.is-open.Collapsible__trigger:after {
    content: '\2303';
    right: 1rem; /* 10px */
    top: 1rem; /* 10px */
    transition: transform 300ms;
    margin-right: 3rem; /* 30px */
    float:right;
}

.playbook-search-menu {
    width: 30rem;
    height: 3.6rem;
    padding: 2px 8px;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
}

.playbook-search-menu:hover{
    border-color: hsl(0,0%,70%);
}
.usermenu{
    position: absolute !important;
    /* margin-top: -8rem; */
    margin-top: -12.8rem; /* -128px */
    width: -moz-fit-content !important;
    width: fit-content !important;
    /* height: 7rem; */
    padding: 0rem 0rem 5.5rem 0rem /* 0px 0px 55px 0px */ !important;
    /* font-size: 1.2rem !important; */
    font-size: 1.92rem /* 19.2px */ !important;
    background-color: #f8f9fa !important;
    margin-right: 0.5rem; /* 5px */
    overflow: hidden;
}

.glyphicon-user{
    padding-right: 0.5rem; /* 5px */
    /* font-size: 1.3rem; */
    font-size: 2.08rem; /* 20.8px */
}

.glyphicon-user::before{
    margin-left:0.5rem; /* 5px */
    margin-right: 0.5rem; /* 5px */
    
}
.glyphicon-log-out::before{
    /* margin-right: 1rem; */
    margin-right: 1.6rem; /* 16px */
}

.userDropdown:hover{
    background-color:transparent !important;
    color:black !important;
}

.user-profile{
    margin-right: 4rem; /* 40px */
}
.user-profile-login{
    background: rgb(252, 206, 49);
    color: rgb(56, 56, 56);
    cursor: pointer;
    padding: 0.5rem 0.5rem 0rem 0.5rem; /* 5px 5px 0px 5px */
    margin-right: 4rem; /* 40px */
    display:block;
    font-size: 1.5rem; /* 15px */
}
.userPopoverTitle{
    font-size: 80%;
    padding: 0rem 1rem 0rem 0rem; /* 0px 10px 0px 0px */
}

@media (max-width: 767px) {
    .popover-user-drop{
        visibility: hidden;
    }
}
.popover-user-drop{
    position: fixed !important;
    min-width: 12.5rem; /* 125px */
    width: auto;
    top:0.1rem /* 1px */ !important;
    margin: 0.3rem 0rem 0rem 0rem; /* 3px 0px 0px 0px */
}

.popover-logout{
    padding: 0.5rem 0rem 0.5rem 0rem; /* 5px 0px 5px 0px */
}

.popover-logout:hover{
    cursor: pointer;
    background: #afaaaa42;
}

.popover-user-dropdown{
    height: 3.5rem; /* 35px */
    font-size: 1.4rem; /* 14px */
    padding: 0rem 0rem 0rem 1rem; /* 0px 0px 0px 10px */
    background:transparent;
}


.popover-user-dropdown:hover{
    text-decoration: none;
    color:#6f6f6f;
}

.popover .arrow::after, .popover .arrow::before {
    position: absolute;
    display: hidden;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.userProfileContainer{
    list-style: none;
    color:#A9A9A9;
    font-size: 1.2rem; /* 12px */
    background-color: #ececec;
    padding: 1rem 0rem 1rem 3rem; /* 10px 0px 10px 30px */
}

.userProfileSm{
    border-bottom: 0.1rem /* 1px */ solid #80808026;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 0.5rem; /* 5px */
}

.userLogoutSm>a{
    color:#A9A9A9;
}
label{
    /* font-size: 1.8rem; */
    font-size: 2.88rem; /* 28.8px */
    font-style:none;
    /* font-family: Avenir LT; */
    font-weight: 400;
    cursor: pointer;
}

.card-text{
    clear: both;
    font-weight: 300;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;

}
.card-header{
    text-transform: uppercase;
    letter-spacing: 0.9rem; /* 9px */
    color: #18032d;
    /* font-size: 1.2rem; */
    font-size: 1.92rem; /* 19.2px */
    text-align: left;
    /* background-image: linear-gradient(to left, rgb(179, 197, 211), #FFFFFF); */
    background-color: transparent !important;
    /* padding:0rem; */
    padding:0;
    border-bottom: 0.1rem /* 1px */ solid #80808026;
    height: 2.5rem; /* 25px */
}

.card-body{
    margin:2rem 0rem 0rem 0rem; /* 20px 0px 0px 0px */
    /* padding: 0rem !important; */
    padding: 0 !important;
}

.product-card{
    padding: 0rem 0rem 0rem 0rem /* 0px 0px 0px 0px */ !important;
    margin: 4rem 5.5rem 0rem 5.5rem /* 40px 55px 0px 55px */ !important;

}

.product-container{
    margin-right: 0rem; /* 0px */
    margin-left: 0rem; /* 0px */
    /* margin-top: -5rem; */
    margin-top: -8rem; /* -80px */
    width: 100%;
    padding-right: 0rem; /* 0px */
    padding-left: 0rem; /* 0px */

}

@media (min-width: 576px) {
    .main-page-card-deck.card-deck .card {
        margin-right: 3rem;
        margin-left: 3rem;
    }
}

.main-page-card-deck.card-deck {
    margin: 1.5rem 3.5rem !important;
}

.main-page-card-deck.card-deck .card {
    border: none;
    padding: 3.2rem 0;
    margin-top: 2rem /* 20px */ !important;
}

.h3{
    color:#6E4B7C !important;
    /* font-size: 1.9rem !important; */
    font-size: 3.04rem /* 30.4px */ !important;
}
.category{
    margin-left:0.1rem; /* 1px */
    color:#616161cc;
    margin-bottom: 1.5rem; /* 15px */
    text-transform: uppercase;
    font-size: 1.7rem; /* 17px */

    /* border-bottom: 1px solid gray; */
    /* text-transform: uppercase; */
}

.manuals{
    background:transparent;
    margin-bottom: 1rem; /* 10px */
}

.manual-info{
    color:#18032d;
    font-size: 1.5rem; /* 15px */
    margin-bottom: 0rem; /* 0px */
}
.manual-info:hover{
    color:#009DFF;
}

.manuals:hover .manual-info{
    /* background: grey; */
    color:#009DFF !important;
}

.manual-card{
    cursor: pointer;

}

.go-top{
    position: fixed;
    cursor: pointer;
    bottom: 8rem; /* 80px */
    right: 6.5rem; /* 65px */
    width: 3.8rem; /* 38px */
    height: 3.8rem; /* 38px */
    text-align: center;
    box-shadow: 0rem 0rem 0.5rem 0.17rem /* 0px 0px 5px 1.7px */ #ada6a6;
    background: #009DFF;
    border-radius: 3.5rem; /* 35px */
    transition: all 0.5s ease;
}

.icon-up-arrow{
    font-size: 2rem; /* 20px */
    line-height: 4.3rem; /* 43px */
    padding: 0rem 0rem 0rem 1.2rem; /* 0px 0px 0px 12px */
    color:#ffffff;
}

@media screen and (max-width: 768px){
    .product-card {
        padding: 0rem 2rem 0rem 0rem /* 0px 20px 0px 0px */ !important;
        margin: 3.5rem 0rem 0rem 1rem /* 35px 0px 0px 10px */ !important;
    }
}

#searchholderdiv .form-control::-moz-placeholder {
    /* move placeholder text in search a little down to align it with text */
    transform: translate(0, 0.2rem);
}

#searchholderdiv .form-control::placeholder {
    /* move placeholder text in search a little down to align it with text */
    transform: translate(0, 0.2rem);
}
.d-flex {
    display: flex;
    align-items: center;
  }
  


.dropdown-menu-available-versions{
    min-width: -moz-min-content;
    min-width: min-content;
    border: none;
    border-radius: 0rem; /* 0px */
    max-height: -moz-min-content;
    max-height: min-content;
    box-shadow: none;
    padding: 0;
}
   
   
.dropdoown-item-available-versions{
    width: 10rem /* 100px */ !important;
    max-height: 10rem; /* 100px */
    overflow: scroll;
    border: none;
    background: #494b5ae3 !important;
    margin-bottom:0rem; /* 0px */
}
   

.dropdown-menu-version-input{
    width:10rem; /* 100px */
    height: 3rem; /* 30px */
    margin-bottom: 0rem /* 0px */ !important;
    font-size: 1.2rem; /* 12px */
    border:none !important;
    background:transparent;
    background: #494b5ae3 !important;
    color:#FCCE31 !important;
    border-radius: 0rem /* 0px */ !important;
}

.dropdown-menu-version-input::-moz-placeholder{
    color:#ffffff !important;
    font-style: italic;
    font-size: 1.2rem; /* 12px */
}

.dropdown-menu-version-input::placeholder{
    color:#ffffff !important;
    font-style: italic;
    font-size: 1.2rem; /* 12px */
}

.dropdown-menu-version-input:focus{
    box-shadow: none !important;
}


.dropdown-menu-version-item{
    color:white;
    font-size: 1.2rem; /* 12px */
}

.dropdown-menu-version-item:hover{
    color: #FCCE31;
    text-decoration: none;
    background-color: #3b3b44;
    
}

.available-version-dropdown-button:hover{
    text-decoration: none;
    color: black;
}
#search-results em {
    display: inline-block;
    font-weight: 600;
    color:#0199b0;
    font-style: normal;
    font-family:avenir;
}

.resultItem:hover{
    background: #f8f9fa;
}
.form-control {
    height: 3.8rem; /* 38px */
}
.version-badge:focus, .version-badge:hover {
    text-decoration: none !important;
    background-color: #575b71 !important;
}

.version-current {
    font-size: 1.4rem; /* 14px */
    font-weight: 200;
    letter-spacing: 0.05rem; /* 0.5px */
    color: #616161;
    background: inherit;
    padding: 0.3rem; /* 3px */
    margin-left: 0.5rem; /* 5px */
    border: 0.1rem /* 1px */ solid #616161;
    opacity: 0.5;
}

.label-info{
    margin: 0.3rem 0.5rem 0rem 0rem; /* 3px 5px 0px 0px */
    padding: 0.3rem 0.6rem 0.3rem 0.6rem; /* 3px 6px 3px 6px */
    /* font-size: 1.1rem; */
    font-size: 1.76rem; /* 17.6px */
    border-radius: 0.5rem; /* 5px */
    font-style: italic;
    background-color: #FCCE31;
}

.card-available-version{
    font-size: 85%;
    text-align: left;
    /* background-image: linear-gradient(to left, rgb(179, 197, 211), #FFFFFF); */
    border: none;
}

.available-versions:hover{
    color:#f0ad4e !important;
}


.version{
    color:black;
    background:  #ffffff;
    padding: 0.5rem 1.2rem 0.3rem 1.2rem; /* 5px 12px 3px 12px */
    border: 0.1rem /* 1px */ solid black;
}

.available-versions{
    font-size: 90%;
    margin: 0.5rem 0rem 0rem 1.5rem; /* 5px 0px 0px 15px */
}

.available-versions-dropdown-button{
    font-size: 2rem; /* 20px */
    margin: 0.5rem 0rem 0rem 1.5rem; /* 5px 0px 0px 15px */
    font-weight: 400;
}

.available-versions-dropdown-button:hover{
    text-decoration: none !important;
    color:black;
}

.version:hover{
    background: #535876;
    color:white !important;
    text-decoration: none;
}

.version-badge{
    background-color: #696e88;
    font-size: 90%;
    padding: 0.6rem 1.5rem; /* 6px 15px */
    border-radius: 0rem; /* 0px */
    text-transform: capitalize;

}

.version-card{
    margin-top:0rem; /* 0px */
    background: transparent;
    border: none;
}
.search-result-guide-path{
    margin-bottom: 20rem; /* 200px */
    font-size: 1.4rem; /* 14px */
    white-space: pre-wrap;
    font-weight: 600;
    letter-spacing: 0.05rem; /* 0.5px */
    color: black;
}

.search-result-title{
    font-size: 1.4rem; /* 14px */
    font-style: italic;
    font-weight: 600;
    color: black;
}

.search-result-texts{
    font-size: 1.3rem; /* 13px */
    color:black;
    margin-bottom: 0rem; /* 0px */
    line-height: 1.7rem; /* 17px */
}


.search-version-card{
    margin-left: 0; 
    /* margin-top: 3rem; */
    margin-top: 4.8rem; /* 48px */
}

@media screen and (max-width: 768px) {
    .search-version-card{
        margin-left: 2rem; /* 20px */
        margin-top: 0;
    }

    .single-result{
        background-color: #d9d9d92e;
        margin-bottom: 1rem; /* 10px */
        border-radius: 0.5rem; /* 5px */
    }
}
.searchFormControl{
    border: none !important;
    height:3.9rem /* 39px */ !important;
}

.searchForm{
    width: 100% !important;
    border-bottom: 0.1rem /* 1px */ solid #d0d0d0b0 !important;
    height: 4rem; /* 40px */
}
.searchFormControl::-moz-placeholder{
    font-style: italic;
}
.searchFormControl::placeholder{
    font-style: italic;
}

.searchFormSubmit{
    background: #009DFF;
    border-radius: 0rem; /* 0px */
    font-size: 1.4rem; /* 14px */
    font-weight: 600;
    height:4rem; /* 40px */
}

.search-icon {
    margin-top: 0.5rem; /* 5px */
}

i[class^="icon-search-icon"]:before, i[class*=" icon-search-icon"]:before {
    font-size: 2.2rem /* 22px */ !important;
}

.has-search .form-control {
    /* padding-left: 2.375rem; */
    padding-left: 3.8rem; /* 38px */
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    /* width: 2.375rem; */
    width: 3.8rem; /* 38px */
    text-align: center;
    pointer-events: none;
    color: #aaa;
    border:none;
}

.searchFormControl:focus{
    box-shadow: none !important;
}
.search-bar{
    padding-top: 0.3rem; /* 3px */
    margin-right: 4rem; /* 40px */
    /* margin-top: 8rem; */
    margin-top: 12.8rem; /* 128px */
    margin-left: 4rem; /* 40px */
}

.css-tlfecz-indicatorContainer {
    color: #000000bf !important;
}

@media screen and (max-width: 768px) {
    .search-bar {
        margin-right: 1rem; /* 10px */
        margin-top: 1rem; /* 10px */
        margin-left: 1rem; /* 10px */
        /* padding-top: 4rem; */
        padding-top: 6.4rem; /* 64px */
    }
}
.ant-layout-header {
  background: #ffffff;
}

.detail-text {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entity-detail {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #1890ff !important;
  z-index: 2;
}

.ant-collapse {
  background-color: unset;
  border-bottom: 1px solid #d9d9d9;
}

.collapse-1 {
  border: unset;
}

.collapse-0 > .playbook-item {
  padding: 16px;
}

.tab-list {
  margin-top: 16px;
  background-color: rgb(255, 255, 255);
}

.ant-layout-content {
  background-color: unset;
}

.ant-page-header {
  padding: unset !important;
}

.ant-page-header-heading {
  padding: 16px 24px;
  padding-bottom: unset;
  position: fixed;
  z-index: 10;
  background-color: white;
  width: 100%;
}

.ant-tabs-nav {
  padding: 16px 24px;
  padding-bottom: unset;
  padding-top: unset;
  margin-top: 54px !important;
  position: fixed !important;
  z-index: 10;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

.ant-tabs-tabpane {
  padding: 12px 24px;
}

.ant-page-header-footer {
  margin-top: unset;
}

.ant-avatar > img {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.ant-tabs-content-holder {
  margin-top: 152px;
}

.input-container {
  display: flex;
  z-index: 10;
  position: fixed;
  width: 100%;
  padding: 12px 24px;
  background-color: white;
  margin-top: 96px;
  border-bottom: 1px solid #d9d9d9;
}

.ant-card {
  cursor: pointer;
}
/* ==============================================================

Template name : Bootsnav - Multi Purpose Header
Categorie : Bootstrap Menu in CSS
Author : adamnurdin01
Version : v.1.1
Created : 2016-06-02
Last update : 2016-06-11

============================================================== */
/*
Typography
=========================== */
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    /* font-family: 'Avenir'; */
    position: relative;
    font-size: 1.4rem; /* 14px */
    line-height: 2.4rem; /* 24px */
    color: #6f6f6f;
}

a {
    color: #6f6f6f;
}

a,
a:active,
a:focus,
a:hover {
    outline: none;
    transition: all 0.3s ease-in-out;
}

a:hover {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #3f3f3f;
    margin: 0 0 1.5rem 0; /* 15px */
    letter-spacing: 0.1rem; /* 1px */
    font-weight: bold;
}

h1 {
    font-size: 4.8rem; /* 48px */
    line-height: 5.2rem; /* 52px */
}

h2 {
    font-size: 3.6rem; /* 36px */
    line-height: 4.8rem; /* 48px */
}

h3 {
    font-size: 3rem; /* 30px */
    line-height: 3.6rem; /* 36px */
}

h4 {
    font-size: 2.4rem; /* 24px */
    line-height: 3rem; /* 30px */
}

h5 {
    font-size: 1.8rem; /* 18px */
    line-height: 2.4rem; /* 24px */
}

h6 {
    font-size: 1.4rem; /* 14px */
    line-height: 1.8rem; /* 18px */
}

p {
    margin-bottom: 2rem; /* 20px */
}

.wrapper {
    min-height: 80rem; /* 800px */
}

/*
Navbar
=========================== */
/* Navbar Atribute ------*/
.attr-nav>ul>li>a {
    padding: 2.8rem 1.5rem; /* 28px 15px */
}

ul.cart-list>li.total>.btn {
    border-bottom: solid 0.1rem /* 1px */ #cfcfcf !important;
    color: #fff !important;
    padding: 1rem 1.5rem; /* 10px 15px */
}

@media (min-width: 1024px) {

    /* Navbar General ------*/
    nav.navbar ul.nav>li>a {
        padding: 3rem 1.5rem; /* 30px 15px */
        font-weight: 600;
    }

    nav.navbar .navbar-brand {
        margin-top: 0;
    }



    nav.navbar .navbar-brand {
        margin-top: 0;
    }

    nav.navbar .navbar-brand img.logo {
        position: relative;
        
        top: 0.2rem; /* 2px */
    }

    nav.navbar.sticked .navbar-brand img.logo {
        position: relative;
        top: 0.2rem; /* 2px */
        /* max-width: 80%; */
    }

    nav.navbar li.dropdown ul.dropdown-menu {
        border-top: solid 0.5rem; /* 5px */
    }

    /* Navbar Center ------*/
    nav.navbar-center .navbar-brand {
        margin: 0 !important;
    }

    /* Navbar Brand Top ------*/
    nav.navbar-brand-top .navbar-brand {
        margin: 1rem /* 10px */ !important;
    }

    /* Navbar Full ------*/
    nav.navbar-full .navbar-brand {
        position: relative;
        top: -1.5rem; /* -15px */
    }

    /* Navbar Sidebar ------*/
    nav.navbar-sidebar ul.nav,
    nav.navbar-sidebar .navbar-brand {
        margin-bottom: 5rem; /* 50px */
    }

    nav.navbar-sidebar ul.nav>li>a {
        padding: 1rem 1.5rem; /* 10px 15px */
        font-weight: bold;
    }

    /* Navbar Transparent & Fixed ------*/
    nav.navbar.bootsnav.navbar-transparent.white {
        background-color: rgba(255, 255, 255, 0.3);
        border-bottom: solid 0.1rem /* 1px */ #bbb;
    }

    nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark,
    nav.navbar.bootsnav.navbar-transparent.dark {
        background-color: rgba(0, 0, 0, 0.3);
        border-bottom: solid 0.1rem /* 1px */ #555;
    }

    nav.navbar.bootsnav.navbar-transparent.white .attr-nav {
        border-left: solid 0.1rem /* 1px */ #bbb;
    }

    nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav,
    nav.navbar.bootsnav.navbar-transparent.dark .attr-nav {
        border-left: solid 0.1rem /* 1px */ #555;
    }

    nav.navbar.bootsnav.no-background.white .attr-nav>ul>li>a,
    nav.navbar.bootsnav.navbar-transparent.white .attr-nav>ul>li>a,
    nav.navbar.bootsnav.navbar-transparent.white ul.nav>li>a,
    nav.navbar.bootsnav.no-background.white ul.nav>li>a {
        color: #fff;
    }

    nav.navbar.bootsnav.navbar-transparent.dark .attr-nav>ul>li>a,
    nav.navbar.bootsnav.navbar-transparent.dark ul.nav>li>a {
        color: #eee;
    }
}

@media (max-width: 992px) {

    /* Navbar General ------*/
    nav.navbar .navbar-brand {
        margin-top: 0;
        position: relative;
        top: -0.2rem; /* -2px */
    }

    nav.navbar .navbar-brand img.logo {
        width: 15rem; /* 150px */
        margin-top: 0.8rem; /* 8px */
        height: auto;
    }

    .attr-nav>ul>li>a {
        padding: 1.6rem 1.5rem 1.5rem; /* 16px 15px 15px */
    }

    /* Navbar Mobile slide ------*/
    nav.navbar.navbar-mobile ul.nav>li>a {
        padding: 1.5rem 1.5rem; /* 15px 15px */
    }

    nav.navbar.navbar-mobile ul.nav ul.dropdown-menu>li>a {
        padding-right: 1.5rem /* 15px */ !important;
        padding-top: 1.5rem /* 15px */ !important;
        padding-bottom: 1.5rem /* 15px */ !important;
    }

    nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title {
        padding-right: 3rem /* 30px */ !important;
        padding-top: 1.3rem /* 13px */ !important;
        padding-bottom: 1.3rem /* 13px */ !important;
    }

    nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu ul.menu-col li a {
        padding-top: 1.3rem /* 13px */ !important;
        padding-bottom: 1.3rem /* 13px */ !important;
    }

    /* Navbar Full ------*/
    nav.navbar-full .navbar-brand {
        top: 0;
        padding-top: 1rem; /* 10px */
    }
}

/* Navbar Inverse
=================================*/
nav.navbar.navbar-inverse {
    background-color: #222;
    border-bottom: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse ul.cart-list>li.total>.btn {
    border-bottom: solid 0.1rem /* 1px */ #222 !important;
}

nav.navbar.navbar-inverse ul.cart-list>li.total .pull-right {
    color: #fff;
}

nav.navbar.navbar-inverse.megamenu ul.dropdown-menu.megamenu-content .content ul.menu-col li a,
nav.navbar.navbar-inverse ul.nav>li>a {
    color: #eee;
}

nav.navbar.navbar-inverse ul.nav>li.dropdown>a {
    background-color: #222;
}

nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu>li>a {
    color: #999;
}

nav.navbar.navbar-inverse ul.nav .dropdown-menu h1,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h2,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h3,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h4,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h5,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h6 {
    color: #fff;
}

nav.navbar.navbar-inverse .form-control {
    background-color: #333;
    border-color: #303030;
    color: #fff;
}

nav.navbar.navbar-inverse .attr-nav>ul>li>a {
    color: #eee;
}

nav.navbar.navbar-inverse .attr-nav>ul>li.dropdown ul.dropdown-menu {
    background-color: #222;
    border-left: solid 0.1rem /* 1px */ #303030;
    border-bottom: solid 0.1rem /* 1px */ #303030;
    border-right: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse ul.cart-list>li {
    border-bottom: solid 0.1rem /* 1px */ #303030;
    color: #eee;
}

nav.navbar.navbar-inverse ul.cart-list>li img {
    border: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse ul.cart-list>li.total {
    background-color: #333;
}

nav.navbar.navbar-inverse .share ul>li>a {
    background-color: #555;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu {
    border-right: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul>li>a {
    border-bottom: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-content {
    border-left: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul>li>a:hover,
nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul>li>a:focus,
nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul>li.active>a {
    background-color: #333 !important;
}

nav.navbar-inverse.navbar-full ul.nav>li>a {
    border: none;
}

nav.navbar-inverse.navbar-full .navbar-collapse .wrap-full-menu {
    background-color: #222;
}

nav.navbar-inverse.navbar-full .navbar-toggle {
    background-color: #222 !important;
    color: #6f6f6f;
}

@media (min-width: 1024px) {
    nav.navbar.navbar-inverse ul.nav .dropdown-menu {
        background-color: #222 !important;
        border-left: solid 0.1rem /* 1px */ #303030 !important;
        border-bottom: solid 0.1rem /* 1px */ #303030 !important;
        border-right: solid 0.1rem /* 1px */ #303030 !important;
    }

    nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu>li>a {
        border-bottom: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse ul.dropdown-menu.megamenu-content .col-menu {
        border-left: solid 0.1rem /* 1px */ #303030;
        border-right: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse.navbar-transparent.dark {
        background-color: rgba(0, 0, 0, 0.3);
        border-bottom: solid 0.1rem /* 1px */ #999;
    }

    nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav {
        border-left: solid 0.1rem /* 1px */ #999;
    }

    nav.navbar.navbar-inverse.no-background.white .attr-nav>ul>li>a,
    nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav>ul>li>a,
    nav.navbar.navbar-inverse.navbar-transparent.dark ul.nav>li>a,
    nav.navbar.navbar-inverse.no-background.white ul.nav>li>a {
        color: #fff;
    }

    nav.navbar.navbar-inverse.no-background.dark .attr-nav>ul>li>a,
    nav.navbar.navbar-inverse.no-background.dark .attr-nav>ul>li>a,
    nav.navbar.navbar-inverse.no-background.dark ul.nav>li>a,
    nav.navbar.navbar-inverse.no-background.dark ul.nav>li>a {
        color: #3f3f3f;
    }
}

@media (max-width: 992px) {
    nav.navbar.navbar-inverse .navbar-toggle {
        color: #eee;
        background-color: #222 !important;
    }

    nav.navbar.navbar-inverse .navbar-nav>li>a {
        border-top: solid 0.1rem /* 1px */ #303030;
        border-bottom: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse ul.nav li.dropdown ul.dropdown-menu>li>a {
        color: #999;
        border-bottom: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu .title {
        border-bottom: solid 0.1rem /* 1px */ #303030;
        color: #eee;
    }

    nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu ul>li>a {
        border-bottom: solid 0.1rem /* 1px */ #303030;
        color: #999 !important;
    }

    nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu.on:last-child .title {
        border-bottom: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul {
        border-top: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse.navbar-mobile .navbar-collapse {
        background-color: #222;
    }
}

@media (max-width: 767px) {
    nav.navbar.navbar-inverse.navbar-mobile ul.nav {
        border-top: solid 0.1rem /* 1px */ #222;
    }

    h1 {
        font-size: 3rem; /* 30px */
        line-height: 3.2rem; /* 32px */
    }

    h2 {
        font-size: 2.4rem; /* 24px */
        line-height: auto;
    }

    h3 {
        font-size: 2.6rem; /* 26px */
        line-height: 2.4rem; /* 24px */
    }

    h4 {
        font-size: 2rem; /* 20px */
        line-height: 2rem; /* 20px */
    }

    h5 {
        font-size: 1.6rem; /* 16px */
        line-height: auto;
    }

    h6 {
        font-size: 1.2rem; /* 12px */
        line-height: 1rem; /* 10px */
    }

    p {
        margin-bottom: 1rem; /* 10px */
    }
}


body {
    background: #ffffff;
    font-family: 'Noto Sans' !important;
    font-size: 1.5rem; /* 15px */
    color: #636e7b;
    font-weight: 200;
}

section {
    padding: 7rem 0rem 6rem 0rem; /* 70px 0px 60px 0px */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #535876;
    margin: 0 0 1.5rem 0; /* 15px */
    letter-spacing: 0.1rem; /* 1px */
    font-weight: 300;
    /* font-family: 'Avenir;' */
}

.dark {
    background: #f4f5f7;
}

.form-control {
    border: 0.1rem /* 1px */ solid #ccc;
    border-radius: 0.2rem; /* 2px */
    height: 4.5rem; /* 45px */
    margin-bottom: 1rem; /* 10px */
    box-shadow: none;
    font-size: 1.4rem; /* 14px */
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #da0833;
    border-color: #da0833;
    text-transform: uppercase;
    border-radius: 0.2rem; /* 2px */
    box-shadow: none;
    padding: 1rem 2.5rem; /* 10px 25px */
}

.form-control::-moz-placeholder {
    color: #8796a9;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #8796a9
}

.form-control::-webkit-input-placeholder {
    color: #8796a9
}

/*---------------Navigation Start-----------------*/
nav.navbar.bootsnav ul.nav>li>a {
    color: #6b797c;
    font-weight: 400;
    font-size: 1.42rem; /* 14.2px */
    /* font-family: 'Avenir'; */
    background-color: transparent !important;
}

nav.navbar.bootsnav ul.nav>li>a:hover {
    color: #FCCE30;
}

nav.navbar.bootsnav ul.nav>li.active>a {
    color: #FCCE30;
}

@media (min-width: 1024px) {
    nav.navbar li.dropdown ul.dropdown-menu {
        border-top: solid 0.5rem /* 5px */ #FCCE30 !important;
    }
}

/*-------------------Banner Start-----------------------*/
.main-banner {
    background-size: cover !important;
    background-position: center !important;
    position: relative;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 7rem 0rem 13rem 0rem; /* 70px 0px 130px 0px */
}

.main-banner .caption {
    position: relative;
    /* top:5.5rem; */
    top: 8.8rem; /* 88px */
}

.main-banner h2 {
    color: #ffffff;
    font-weight: 400;
    display: block;
    margin-bottom: 2.5rem; /* 25px */
}

.main-banner:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: #18032C;
    background-repeat: no-repeat;
    background-size: cover;
}

fieldset {
    background: rgba(255, 255, 255, 0.3);
    padding: 2rem 2rem 1rem 2rem; /* 20px 20px 10px 20px */
}

fieldset .no-pad {
    margin: 0;
    padding: 0.5rem; /* 5px */
}

.main-banner fieldset select.selectpicker,
.main-banner fieldset .form-control,
.main-banner fieldset .seub-btn {
    width: 100%;
    color: #6b797c;
    padding: 1.9rem 1.5rem; /* 19px 15px */
    border: none;
    border-radius: 0;
    height: auto;
    line-height: 1.5;
    font-size: 1.6rem; /* 16px */
    height: 6rem; /* 60px */
}

.main-banner fieldset select.selectpicker:focus,
.main-banner fieldset .form-control:focus,
.main-banner fieldset .seub-btn:focus {
    outline: none;
}

.main-banner fieldset .border-right {
    border-right: 0.1rem /* 1px */ solid rgba(107, 121, 124, 0.3) !important;
}

.main-banner .btn.seub-btn {
    background: #009DFF;
    color: #ffffff !important;
    text-transform: uppercase;
    font-size: '1.3rem' /* small === 13px */;
    text-shadow: none;
    line-height: 1.2;
}
.main-banner .btn.seub-btn:hover {
    background: #43474C;
}
/*--------------------Default Page Header-----------------------*/
.jumbotron {
    background: url(/img/background.jpg);
    background-size: cover;
    background-position: center;
    padding: 13% 0;
    position: relative;
    text-align: center;
    color: #ffffff;
    margin: 0;
}

.jumbotron:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: rgba(12, 20, 25, 0.5);
}

.user-img {
    max-width: 22rem; /* 220px */
    position: relative;
    margin: 0 auto;
}

.jumbotron img {
    display: table;
    max-width: 20rem; /* 200px */
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    border: 0.6rem /* 6px */ solid rgba(255, 255, 255, 0.8);
}

.caption.bott {
    margin-bottom: 8rem; /* 80px */
}

.notify {
    position: relative;
    margin-top: -0.5rem; /* -5px */
}

.notify .ring {
    position: absolute;
    top: -2rem; /* -20px */
    left: 5rem; /* 50px */
    height: 2.5rem; /* 25px */
    width: 2.5rem; /* 25px */
    z-index: 10;
    border: 0.5rem /* 5px */ solid #da0833;
    border-radius: 100%;
    -moz-animation: ring 1s ease-in;
    -moz-animation-iteration-count: infinite;
    -o-animation: ring 1s ease-in;
    -o-animation-iteration-count: infinite;
    -webkit-animation: ring 1s ease-in;
    animation-iteration-count: infinite;
}

.notify .ring-point {
    width: 0.6rem; /* 6px */
    height: 0.6rem; /* 6px */ /* 30px */ /* 30px */
    border-radius: 100%;
    background-color: #da0833;
    position: absolute;
    left: 5.9rem; /* 59px */
    top: -1rem;
}

.jumbotron h4 {
    font-size: 2.8rem; /* 28px */
    margin-top: 1.2rem; /* 12px */
    margin-bottom: 1rem; /* 10px */
    position: relative;
    color: #ffffff;
}

.jumbotron p {
    font-size: 1.7rem; /* 17px */
    position: relative;
}

.jumbotron p span {
    font-size: 1.4rem; /* 14px */
    color: #13ad00;
}

.basic-information {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 3rem 3rem 2rem; /* 30px 30px 20px */
    box-shadow: 0 0.5rem 2.5rem 0 /* 5px 25px */ rgba(0, 0, 0, .07);
    border: 0.1rem /* 1px */ solid #eaecf1;
    display: table;
    width: 100%;
}

.profile-detail img {
    border: 0.4rem /* 4px */ solid rgba(107, 121, 124, 0.2);
}

.basic-information h2 {
    margin: 0;
    margin-bottom: 1rem; /* 10px */
    color: #404b4d;
}

.basic-information h2 span {
    color: #da0833;
    font-size: 1.55rem; /* 15.5px */
    display: block;
}

.basic-information p {
    font-size: 1.7rem; /* 17px */
}

.information {
    margin: 0;
    padding: 0;
}

.information li {
    display: block;
    padding: 1rem 0; /* 10px */
    font-size: 15.5;
    list-style: none;
    border-bottom: 0.1rem /* 1px */ solid rgba(107, 121, 124, 0.1);
}

.information li span {
    display: inline-block;
    font-weight: bold;
    width: 15rem; /* 150px */
}

.social {
    margin: 0;
    padding: 5rem 0; /* 50px */
    display: inline-block;
    width: 100%;
}

.social li {
    list-style: none;
    float: left;
    margin-bottom: 0.7rem; /* 7px */
}

.social li a {
    font-size: 1.5rem; /* 15px */
    color: #6b797c;
}

.social li:hover a,
.social li:focus a {
    text-decoration: none;
}

a.facebook {
    border: 0.1rem /* 1px */ solid #295396;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

a.google {
    border: 0.1rem /* 1px */ solid #d6201f;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

a.twitter {
    border: 0.1rem /* 1px */ solid #1da0f2;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

a.linkedin {
    border: 0.1rem /* 1px */ solid #0077b5;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

a.instagram {
    border: 0.1rem /* 1px */ solid #d3368c;
    width: 90%;
    display: inline-block;
    padding: 0.4rem 0.5rem; /* 4px 5px */
    border-radius: 5rem; /* 50px */
}

.social li i {
    width: 4.2rem; /* 42px */
    height: 4.2rem; /* 42px */
    border-radius: 50%;
    color: #ffffff;
    line-height: 4.2rem; /* 42px */
    text-align: center;
    margin-right: 0.7rem; /* 7px */
}

.social li .facebook i {
    background: #295396;
}

.social li .google i {
    background: #d6201f;
}

.social li .twitter i {
    background: #1da0f2;
}

.social li .linkedin i {
    background: #0077b5;
}

.social li .instagram i {
    background: #d3368c;
    ;
}

.panel {
    box-shadow: none;
    border: none;
}

.panel-default>.panel-heading {
    background: #f4f5f7;
    box-shadow: none;
    border: none;
    color: #505a5d;
}

.basic-information p {
    font-size: 1.6rem; /* 16px */
    line-height: 1.9;
}

.service-tag {
    background-color: #da0833;
    border-radius: 0 0.2rem 0.2rem 0; /* 2px 2px */
    color: #fff;
    display: inline-block;
    font-size: 1.1rem; /* 11px */
    font-weight: 700;
    line-height: 1.2727272727;
    margin: 0.2rem 0.4rem 0.2rem 1rem; /* 2px 4px 2px 10px */
    padding: 0.3rem 0.7rem; /* 3px 7px */
    position: relative;
    text-transform: uppercase;
}

.service-tag:after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 0.4rem; /* 4px */
    position: absolute;
    top: 0.8rem; /* 8px */
    left: -0.2rem; /* -2px */
    width: 0.4rem; /* 4px */
}

.service-tag:before {
    border-top: 1rem /* 10px */ solid transparent;
    border-right: 0.8rem /* 8px */ solid #da0833;
    border-bottom: 1rem /* 10px */ solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: 0;
    left: -0.8rem; /* -8px */
    width: 0;
}

.panel-body ul {
    margin: 0;
    padding: 0;
    margin-top: 2rem; /* 20px */
}

.panel-body ul li {
    list-style: none;
    line-height: 1.7;
    padding: 0 0 0.6rem 1rem; /* 6px 10px */
    margin: 1rem 0; /* 10px */
    margin-left: 1.6rem; /* 16px */
}

.panel-body ul li:before {
    display: inline-block;
    font: normal normal normal 1.4rem/1 /* 14px */ FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: "\f067";
    color: #da0833;
    padding: 0.8rem 1.2rem 0.8rem 0.5rem; /* 8px 12px 8px 5px */
    margin-left: -3.1rem; /* -31px */
}

/*----------------------------Features Style-------------------------*/
.features-content {
    border: 0.1rem /* 1px */ solid #f4f5f7;
    padding: 2rem 1.5rem; /* 20px 15px */
    text-align: center;
    border-radius: 0.5rem; /* 5px */
    margin-bottom: 3rem; /* 30px */
    box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
}

.features-content h3 {
    font-weight: 400;
    color: #475052;
    font-size: 1.8rem; /* 18px */
    text-transform: uppercase;
    margin-bottom: 0;
}


.features-content p {
    font-weight: 300;
    color: #6b797c;
    line-height: 1.8;
    font-size: 1.4rem; /* 14px */
}

.features-content span {
    font-size: 4.5rem; /* 45px */
    margin-bottom: 2.5rem; /* 25px */
    color: #FCCE30;
}

/*--------------------Style for Browse Job----------------------*/
.jobs .row {
    margin: 0;
}

.heading {
    text-align: center;
    margin-bottom: 3rem; /* 30px */
}

.heading h2 {
    font-weight: 400;
    margin-bottom: 1rem; /* 10px */
}

.heading p {
    font-weight: 300;
    font-size: 1.7rem; /* 17px */
}

.companies {
    margin-top: 3rem; /* 30px */
}

.company-logo {
    display: table;
    width: 100%;
}

.company-logo img {
    margin: 0 auto;
    max-width: 10rem /* 100px */
}

.company-content {}

.company-content h3 {
    font-size: 2.5rem; /* 25px */
    font-weight: 400;
    margin-bottom: 1rem; /* 10px */
    margin-top: 0.7rem; /* 7px */
}

.company-content h3 span {
    font-size: 1.3rem; /* 13px */
    padding: 0.2rem 2rem; /* 2px 20px */
    float: right;
    text-align: center;
    color: #ffffff;
    margin-top: 0.5rem; /* 5px */
    display: inline-block;
    line-height: 1.7;
    border-radius: 0.2rem; /* 2px */
}

.company-content p span {
    font-size: 1.5rem; /* 15px */
    margin-right: 2rem; /* 20px */
    margin-bottom: 1rem; /* 10px */
}

.company-content p span i {
    font-size: 1.5rem; /* 15px */
    margin-right: 0.5rem; /* 5px */
}

.company-list {
    background: #ffffff;
    margin: 1.2rem 0; /* 12px */
    padding: 1.2rem 0 1.2rem 0; /* 12px 12px */
    border: 0.1rem /* 1px */ solid #f4f5f7;
    box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
}

.company-content h3 .full-time {
    background: #1ec715;
}

.company-content h3 .freelance {
    background: #01b5ff;
}

.company-content h3 .internship {
    background: #cc68f3;
}

.company-content h3 .part-time {
    background: #ff8f3e;
}

.brows-btn {
    background: #da0833;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.6rem; /* 16px */
    display: table;
    text-shadow: none;
    line-height: 1.2;
    margin: 2rem /* 20px */ auto;
    font-weight: 400;
    padding: 1.4rem 2.5rem; /* 14px 25px */
}

/*--------------------Style for Job Filter----------------------*/
.jobs {
    padding: 6rem 0; /* 60px */
}

.jobs .row {
    margin: 0;
}

.heading {
    text-align: center;
    margin-bottom: 3rem; /* 30px */
}

.heading h2 {
    font-weight: 400;
    margin-bottom: 1rem; /* 10px */
}

.heading p {
    font-weight: 300;
    font-size: 2rem; /* 20px */
}

.companies {
    margin-top: 3rem; /* 30px */
}

.company-logo {
    display: table;
    width: 100%;
}

.company-logo img {
    margin: 0 auto;
    max-width: 10rem /* 100px */
}

.company-content {}

.company-content h3 {
    font-size: 2.5rem; /* 25px */
    font-weight: 400;
    margin-bottom: 1rem; /* 10px */
    margin-top: 0.7rem; /* 7px */
}

.company-content h3 span {
    font-size: 1.3rem; /* 13px */
    padding: 0.2rem 2rem; /* 2px 20px */
    float: right;
    text-align: center;
    color: #ffffff;
    margin-top: 0.5rem; /* 5px */
    display: inline-block;
    line-height: 1.7;
    border-radius: 0.2rem; /* 2px */
}

.company-content p span {
    font-size: 1.5rem; /* 15px */
    margin-right: 2rem; /* 20px */
    margin-bottom: 1rem; /* 10px */
}

.company-content p span i {
    font-size: 1.5rem; /* 15px */
    margin-right: 0.5rem; /* 5px */
}

.company-list {
    background: #ffffff;
    margin: 1.2rem 0; /* 12px */
    padding: 1.2rem 0 1.2rem 0; /* 12px 12px */
    border: 0.1rem /* 1px */ solid rgba(107, 121, 124, 0.3);
    box-shadow: 0 0.5rem 2.5rem 0 /* 5px 25px */ rgba(0, 0, 0, .07);
}

.brows-btn {
    background: #da0833;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.6rem; /* 16px */
    display: table;
    text-shadow: none;
    line-height: 1.2;
    margin: 2rem /* 20px */ auto;
    font-weight: 400;
    padding: 1.4rem 2.5rem; /* 14px 25px */
}

.btn.view-job {
    border: none;
    background: #007ffe;
    border-radius: 0.1rem; /* 1px */
    padding: 1em 2em;
    display: block;
    margin-top: 2.5rem; /* 25px */
    width: 100%;
    color: #ffffff;
}

.top-pad {
    padding-top: 3em;
}

.filter-list {
    margin: 0;
    padding: 0;
}

.filter-list li {
    margin: 0;
    padding: 0;
    font-weight: 300;
    list-style: none;
    padding-left: 1rem; /* 10px */
    display: inline-block;
}

.filter-list label {
    font-weight: normal;
    margin: 0 0.5rem; /* 5px */
}

.checkbox-custom,
.radio-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 0.5rem; /* 5px */
    cursor: pointerer;
}

.checkbox-custom-label,
.radio-custom-label {
    position: relative;
}

.checkbox-custom+.checkbox-custom-label:before,
.radio-custom+.radio-custom-label:before {
    content: '';
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 2rem; /* 20px */
    height: 2rem; /* 20px */
    padding: 0.2rem; /* 2px */
    border-radius: 50%;
    margin-right: 1rem; /* 10px */
    text-align: center;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
    background: #ef193c;
    box-shadow: inset 0rem 0rem 0rem 0.4rem /* 0px 0px 0px 4px */ #fff;
}

.radio-custom+.radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked+.radio-custom-label:before {
    background: #ef193c;
    box-shadow: inset 0rem 0rem 0rem 0.4rem /* 0px 0px 0px 4px */ #fff;
}

.company-brands label {
    font-weight: normal;
    color: #828f99;
    font-size: 1.5rem; /* 15px */
}

.checkbox-custom:focus+.checkbox-custom-label,
.radio-custom:focus+.radio-custom-label {
    outline: none
        /* focus style */
}

.company-brands input[type='checkbox']:focus {
    outline: none;
}

.company-brands label:focus {
    outline: none;
}

.checkbox-custom+.part-time.checkbox-custom-label:before,
.radio-custom+.part-time.radio-custom-label:before {
    border: 0.2rem /* 2px */ solid #0080ff;
}

.checkbox-custom:checked+.part-time.checkbox-custom-label:before {
    background: #0080ff;
}

.checkbox-custom+.full-time.checkbox-custom-label:before,
.radio-custom+.full-time.radio-custom-label:before {
    border: 0.2rem /* 2px */ solid #40af37;
}

.checkbox-custom:checked+.full-time.checkbox-custom-label:before {
    background: #40af37;
}

.checkbox-custom+.freelancing.checkbox-custom-label:before,
.radio-custom+.freelancing.radio-custom-label:before {
    border: 0.2rem /* 2px */ solid #0080ff;
}

.checkbox-custom:checked+.freelancing.checkbox-custom-label:before {
    background: #0080ff;
}

.checkbox-custom+.internship.checkbox-custom-label:before,
.radio-custom+.internship.radio-custom-label:before {
    border: 0.2rem /* 2px */ solid #ef183c;
}

.checkbox-custom:checked+.internship.checkbox-custom-label:before {
    background: #ef183c;
}

/*-----------------------counter Section start-------------------------*/
.counter {
    background: #da0833;
    padding: 6rem 0; /* 60px */
}

.counter-text {
    text-align: center;
    color: #ffffff;
}

.counter-text h3 {
    color: #ffffff;
    font-weight: 400;
    font-size: 3.2rem; /* 32px */
    margin-bottom: 0.5rem; /* 5px */
}

.counter-text p {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.7rem; /* 17px */
    text-transform: uppercase;
    margin-bottom: 0;
}

.counter-text span {
    font-size: 3.5rem; /* 35px */
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 1.5rem; /* 15px */
}

/*--------------------Testimonial Start------------------------*/
.testimonials {
    background: #f4f5f7;
    padding: 6rem 0; /* 60px */
}

.testimonial {
    text-align: center;
    margin: 5rem 1rem 0; /* 50px 10px */

}

.testimonial .pic {
    width: 10rem; /* 100px */
    height: 10rem; /* 100px */
    border-radius: 50%;
    border: 0.5rem /* 5px */ solid rgba(255, 255, 255, 0.3);
    display: inline-block;
    margin-top: -5rem; /* -50px */
    overflow: hidden;
    box-shadow: 0 0.2rem 0.6rem /* 2px 6px */ rgba(0, 0, 0, 0.15);
}

.testimonial .pic img {
    width: 100%;
    height: auto;
}

.testimonial .description {
    font-size: 2.2rem; /* 22px */
    line-height: 3.4rem; /* 34px */
    margin: 1rem 0 2rem; /* 10px 20px */
    /* font-family: 'Avenir'; */
    font-style: italic;
}

.testimonial .testimonial-title {
    font-size: 1.4rem; /* 14px */
    font-weight: bold;
    margin: 0;
    color: #da0833;
    text-transform: uppercase;
}

.testimonial .post {
    display: block;
    font-size: 1.3rem; /* 13px */
    color: #777;
    text-transform: capitalize;
}

.owl-theme .owl-controls {
    margin-top: 1rem; /* 10px */
}

.owl-theme .owl-controls .owl-pagination {
    width: 14rem; /* 140px */
    padding: 1rem; /* 10px */
    margin: 0 auto;
    line-height: 1.3rem; /* 13px */
}

.owl-theme .owl-controls .owl-page span {
    width: 1.2rem; /* 12px */
    height: 1.2rem; /* 12px */
    border-radius: 50%;
    background: transparent;
    border: 0.1rem /* 1px */ solid #6b797c;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    border: 0.4rem /* 4px */ solid #da0833;
}

/*------------------package section start---------------*/
.package-box {
    background: #ffffff;
    border-radius: 0.6rem 0.6rem 0rem 0rem; /* 6px 6px 0px 0px */
    overflow: hidden;
    margin-bottom: 3rem; /* 30px */
    text-align: center;
    padding: 0rem 1rem 3rem 1rem; /* 0px 10px 30px 10px */
    border: 0.1rem /* 1px */ solid #f4f5f7;
    box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
}

.package-header {
    padding: 2rem 0; /* 20px */
}

.package-header i {
    font-size: 3em;

}

.package-header h3 {
    text-transform: uppercase;
    color: #242c36;
    font-size: 2rem; /* 20px */
    font-weight: 600;
}

.package-info ul {
    padding: 0 1.5rem; /* 15px */
    margin: 0;
}

.package-info ul li {
    padding: 1rem 0; /* 10px */
    list-style: none;
    border-bottom: 0.1rem /* 1px */ solid #f4f5f7;
}

.package-price {
    padding: 2rem 0; /* 20px */
}

.package-price h2 {
    color: #242c36;
    font-weight: 600;
}

.package-price h2 sup,
.package-price h2 sub {
    font-size: 2rem; /* 20px */
    opacity: 0.7;
}

button.btn.btn-package {
    background: #da0833;
    color: #ffffff;
    text-transform: uppercase;
    padding: 1rem 3.2rem; /* 10px 32px */
    border-radius: 5rem; /* 50px */
}

button.btn.btn-package:hover,
button.btn.btn-package:focus {
    background: #242c36;
}

/*---------------------Member Card---------------*/
.card-deck {
    margin: 0.5rem /* 5px */ !important;
}

.card {
    padding-bottom: 0.5rem /* 5px */ !important;
}

.card-link {
    color: #18032d !important;
    padding-bottom: 0rem /* 0px */ !important;
}
.card-widget {
    background-color: #fff;
    border-color: #d7dfe3;
    border: none;
    box-shadow: 0rem 0rem 1rem 0rem /* 0px 0px 10px 0px */ rgba(107, 121, 124, 0.2);
    border-radius: 0.6rem; /* 6px */
    text-align: center;
    overflow: hidden;
    transition: .7s;
    margin-bottom: 3rem; /* 30px */
}

.user-card .u-img {
    margin-bottom: -4.2rem; /* -42px */
    height: 15rem; /* 150px */
}

.user-card .u-content {
    /* padding: 0 1.25rem 1.25rem; */
    padding: 0 2rem 2rem; /* 20px 20px */
}

.user-card .avatar {
    /* margin: 0 auto 2rem auto; */
    margin: 0 auto 3.2rem /* 32px */ auto;
    position: relative;
    display: block;
    max-width: 8.5rem; /* 85px */
}

.avatar.box-80 i {
    position: absolute;
    bottom: -0.6rem; /* -6px */
    left: 3.7rem; /* 37px */
    color: #07b107;
}

.u-content h5 {
    font-size: 1.8rem; /* 18px */
    color: #242c36;
    margin-bottom: 0.5rem; /* 5px */
}

.text-muted {
    margin-bottom: 0.5rem; /* 5px */
}

.user-social-profile {
    padding: 0.5rem 0 2.5rem 0; /* 5px 25px */
}

.user-social-profile ul {
    padding: 0;
    margin: 0;
}

.user-social-profile ul li {
    list-style: none;
    display: inline-block;
    padding: 0 0.4rem; /* 4px */
}

.user-social-profile ul li a i {
    background: rgba(99, 110, 123, 0.1);
    width: 4.2rem; /* 42px */
    height: 4.2rem; /* 42px */
    line-height: 4.2rem; /* 42px */
    font-size: 1.7rem; /* 17px */
    border-radius: 50%;
    color: #636e7b;
}

.user-social-profile ul li a i:hover,
.user-social-profile ul li a i:focus {
    background: #da0833;
    color: #ffffff;
}

/*----------------Newsletter Style----------------*/
.newsletter {
    text-align: center;
}

.newsletter h2 {
    font-weight: 500;
    margin-bottom: 0.5rem; /* 5px */
}

.newsletter p {
    font-size: 2.3rem; /* 23px */
    /* font-family: 'Avenir'; */
    font-style: italic;
}

.newsletter input.form-control {
    border: 0.1rem /* 1px */ solid #e3e6ea;
    box-shadow: none;
}

.newsletter button.btn.btn-default {
    background: #da0833;
    text-transform: uppercase;
    border-color: #da0833;
    color: #ffffff;
}

/*------login style---*/
section.login-wrapper {
    text-align: center;
}

section.login-wrapper p a {
    color: #da0833;
}

section.login-wrapper p {
    margin-top: 2rem; /* 20px */
}

section.login-wrapper label,
section.login-wrapper label a {
    font-weight: 400;
    display: block;
    text-align: left;
    color: #636e7b;
}

section.login-wrapper img {
    margin: 2rem /* 20px */ auto;
}

/*-------------------Inner page Start-----------------------*/
.inner-banner {
    background-size: cover !important;
    background-position: center !important;
    padding: 7% 0;
    position: relative;
    text-align: center;
    color: #ffffff;
    margin: 0;
}

.inner-banner .caption {
    position: relative;
}

.caption  h2{
    font-size: 3.6rem /* 36px */ !important;
    line-height: 3.2rem; /* 32px */
    text-transform:uppercase;
}

.caption  h5{
    font-size: 2.4rem /* 24px */ !important;
    line-height: auto;
    color:#ffffff;
}

.inner-banner h2 {
    color: #ffffff;
    font-weight: 400;
    display: block;
    margin-bottom: 2.5rem; /* 25px */
}

.inner-banner p {
    color: #ffffff;
    font-weight: 300;
    display: block;
    font-size: 2rem; /* 20px */
    margin-top: 1.5rem; /* 15px */
}

.inner-banner p span {
    color: #da0833;
}

.inner-banner:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: rgba(12, 20, 25, 0.7);
}

@media only screen and (max-width: 768px) {
    .caption h2 {
        font-size: 2.4rem /* 24px */ !important;
        letter-spacing: 0.4rem; /* 4px */
        line-height: auto;
    }
}


.copy-right {
    background: #18032d;
    padding: 1.2rem 0;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
  }
  
  .copy-right a {
    margin-left: 1.8rem;
    color: #FFFFFF;
    margin-right: 1.5rem;
    font-size: 1.3rem;
    text-decoration: none !important;
  }

footer img {
    margin-bottom: 2rem; /* 20px */
}

footer h4 {
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-size: 2rem; /* 20px */
    font-weight: 400;
}

footer ul {
    margin: 0;
    padding: 0;
}

footer ul li {
    list-style: none;
}

footer ul li a {
    color: #636e7b;
    line-height: 2;
}

footer ul li a:hover {
    color: #da0833;
}

footer address ul li {
    margin-bottom: 1rem; /* 10px */
    line-height: 1.8;
}

footer .form-control {
    border: 0.1rem /* 1px */ solid #3f4a58;
    margin-bottom: 0.7rem; /* 7px */
    border-radius: 0.2rem; /* 2px */
    box-shadow: none;
    color: #8796a9;
    height: 4.5rem; /* 45px */
    font-size: 1.4rem; /* 14px */
    background: #333d4a;
}

footer textarea.form-control {
    height: 7rem; /* 70px */
}

footer button.btn.btn-primary {
    border-radius: 0.2rem; /* 2px */
    min-width: 12rem; /* 120px */
    padding: 1rem 0; /* 10px */
    text-align: center;
    text-transform: uppercase;
    background: #da0833;
    border: 0.1rem /* 1px */ solid #da0833;
}

footer .form-control::-moz-placeholder {
    color: #8796a9;
    opacity: 1
}

footer .form-control:-ms-input-placeholder {
    color: #8796a9
}

footer .form-control::-webkit-input-placeholder {
    color: #8796a9
}

/*---------------------------responsive start-----------------------*/
@media only screen and (max-width: 767px) {
    .social li {
        width: 100%;
    }

    .main-banner select {
        margin-bottom: 1rem; /* 10px */
    }

    .company-content p span {
        display: block;
    }

    .input-group {
        width: 100%;
        display: block;
    }

    .newsletter button.btn.btn-default {
        margin: 0.5rem 0; /* 5px */
        width: 100%;
        height: 4.2rem; /* 42px */
        border-radius: 0.2rem; /* 2px */
    }

    .input-group-addon,
    .input-group-btn {
        display: table;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .inner-banner h2 {
        margin-bottom: 0.2rem; /* 2px */
    }

    .inner-banner p {
        margin-top: 0.2rem; /* 2px */
    }

    .basic-information p {
        font-size: 1.4rem; /* 14px */
        line-height: 1.9;
    }
}

@media only screen and (min-width: 768px) {

    .main-banner h2 {
        font-size: 5rem; /* 50px */
    }

    .inner-banner h2 {
        font-size: 8.2rem; /* 82px */
    }

    .social li {
        width: 20%;
    }

    .newsletter button.btn.btn-default,
    .newsletter input.form-control {
        height: 6rem; /* 60px */
        padding: 0 2rem; /* 20px */
    }

    .md-right{
        text-align: right;
        right: 5.5rem; /* 55px */
    }

    .md-left{
        text-align: left;
        margin-left: 5.5rem; /* 55px */
    }
}



.modalContent {
    margin-left: 3rem; /* 30px */
    margin-right: 3rem; /* 30px */
    text-align: justify;
    text-justify: inter-word;
}

.modalTopic{
    font-color: black;
    font-weight: bold;
    font-size: 1.8rem; /* 18px */
    margin-bottom: 0.5rem; /* 5px */

}

.modalDescription{
    color: black;
    font-size: 1.6rem; /* 16px */
    margin-bottom: 2.5rem; /* 25px */
}
.modalExplanation {
    margin-bottom: 2.5rem; /* 25px */
    font-size: 1.6rem; /* 16px */
}

.modal-header {
    margin: 1rem 3rem 1rem 3rem; /* 10px 30px 10px 30px */
}

.modalExplanation span {
    color: unset !important;
}

.modalExplanation div strong {
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
}

.content-wrapper {
    min-height: 93.5vh;
    background-color: white;
 }
 
 body {
     /* font-family: 'Avenir'; */
     font-weight: 300;
 }
 
 .dropdown-large {
     position: static !important;
 }
 
 .dropdown-menu-large {
     margin-left: 1.6rem; /* 16px */
     margin-right: 1.6rem; /* 16px */
     padding: 2rem 0rem; /* 20px 0px */
 }
 
 .dropdown-menu-large>li>ul {
     padding: 0;
     margin: 0;
 }
 
 .dropdown-menu-large>li>ul>li {
     list-style: none;
 }
 
 .dropdown-menu-large>li>ul>li>a {
     display: block;
     padding: 0.3rem 2rem; /* 3px 20px */
     clear: both;
     font-weight: 300;
     line-height: 1.428571429;
     color: #333333;
     white-space: normal;
 }
 
 .dropdown-menu-large>li ul>li>a:hover,
 .dropdown-menu-large>li ul>li>a:focus {
     text-decoration: none;
     color: #262626;
     background-color: #f5f5f5;
 }
 
 .dropdown-menu-large .disabled>a,
 .dropdown-menu-large .disabled>a:hover,
 .dropdown-menu-large .disabled>a:focus {
     color: #999999;
 }
 
 .dropdown-menu-large .disabled>a:hover,
 .dropdown-menu-large .disabled>a:focus {
     text-decoration: none;
     background-color: transparent;
     background-image: none;
     filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
     cursor: not-allowed;
 }
 
 .dropdown-menu-large .dropdown-header {
     color: #428bca;
     font-size: 1.8rem; /* 18px */
 }
 
 .card-link:hover {
     color: #18032d !important;
 }

 .modal-header .close {
    font-size: 2.5rem;
  }
 
 @media (max-width: 768px) {
     .dropdown-menu-large {
         margin-left: 0;
         margin-right: 0;
     }
 
     .dropdown-menu-large>li {
         margin-bottom: 3rem; /* 30px */
     }
 
     .dropdown-menu-large>li:last-child {
         margin-bottom: 0;
     }
 
     .dropdown-menu-large .dropdown-header {
         padding: 0.3rem 1.5rem /* 3px 15px */ !important;
     }
 }
 
 nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
     width: 122%;
     /* margin-left: -13rem; */
     margin-left: -20.8rem; /* -208px */
 }
 
 .active-product {
     border-bottom: 0.2rem /* 2px */ solid #FCCE31;
 }
 
 .navbar{
     padding-top: 1.5rem /* 15px */ !important;
 }
 a.list-group-item {
     height: auto;
     min-height: 15rem; /* 150px */
     overflow: hidden;
     border: none;
     margin: 0rem 5rem; /* 0px 50px */
     text-decoration: none;
     background: transparent;
     
 }
 
 a.list-group-item:hover{
     color:black;
 }
 
 a.list-group-item.active small {
     color: #fff;
 }
 
 .stars {
     margin: 2rem auto 0.1rem; /* 20px 1px */
 }
 
 .features ul {
     padding-left: 0.4rem; /* 4px */
     list-style: none;
 }
 .label-warning {
     background-color: #535876;
 }
 
 
 @media screen and (max-width: 768px){
     a.list-group-item{
         height: auto;
         min-height: 15rem; /* 150px */
         overflow: hidden;
         border: none;
         margin: 0rem 0rem; /* 0px 0px */
         text-decoration: none;
         background: transparent;
     }
 }
.col-xs-5th-1, .col-xs-5th-2, .col-xs-5th-3, .col-xs-5th-4 {
  float: left;
}

.col-xs-5th-5 {
  float: left;
  width: 100%;
}

.col-xs-5th-4 {
  width: 80%;
}

.col-xs-5th-3 {
  width: 60%;
}

.col-xs-5th-2 {
  width: 40%;
}

.col-xs-5th-1 {
  width: 20%;
}

.col-xs-5th-pull-5 {
  right: 100%;
}

.col-xs-5th-pull-4 {
  right: 80%;
}

.col-xs-5th-pull-3 {
  right: 60%;
}

.col-xs-5th-pull-2 {
  right: 40%;
}

.col-xs-5th-pull-1 {
  right: 20%;
}

.col-xs-5th-pull-0 {
  right: auto;
}

.col-xs-5th-push-5 {
  left: 100%;
}

.col-xs-5th-push-4 {
  left: 80%;
}

.col-xs-5th-push-3 {
  left: 60%;
}

.col-xs-5th-push-2 {
  left: 40%;
}

.col-xs-5th-push-1 {
  left: 20%;
}

.col-xs-5th-push-0 {
  left: auto;
}

.col-xs-5th-offset-5 {
  margin-left: 100%;
}

.col-xs-5th-offset-4 {
  margin-left: 80%;
}

.col-xs-5th-offset-3 {
  margin-left: 60%;
}

.col-xs-5th-offset-2 {
  margin-left: 40%;
}

.col-xs-5th-offset-1 {
  margin-left: 20%;
}

.col-xs-5th-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .col-sm-5th-1, .col-sm-5th-2, .col-sm-5th-3, .col-sm-5th-4 {
    float: left;
  }

  .col-sm-5th-5 {
    float: left;
    width: 100%;
  }

  .col-sm-5th-4 {
    width: 80%;
  }

  .col-sm-5th-3 {
    width: 60%;
  }

  .col-sm-5th-2 {
    width: 40%;
  }

  .col-sm-5th-1 {
    width: 20%;
  }

  .col-sm-5th-pull-5 {
    right: 100%;
  }

  .col-sm-5th-pull-4 {
    right: 80%;
  }

  .col-sm-5th-pull-3 {
    right: 60%;
  }

  .col-sm-5th-pull-2 {
    right: 40%;
  }

  .col-sm-5th-pull-1 {
    right: 20%;
  }

  .col-sm-5th-pull-0 {
    right: auto;
  }

  .col-sm-5th-push-5 {
    left: 100%;
  }

  .col-sm-5th-push-4 {
    left: 80%;
  }

  .col-sm-5th-push-3 {
    left: 60%;
  }

  .col-sm-5th-push-2 {
    left: 40%;
  }

  .col-sm-5th-push-1 {
    left: 20%;
  }

  .col-sm-5th-push-0 {
    left: auto;
  }

  .col-sm-5th-offset-5 {
    margin-left: 100%;
  }

  .col-sm-5th-offset-4 {
    margin-left: 80%;
  }

  .col-sm-5th-offset-3 {
    margin-left: 60%;
  }

  .col-sm-5th-offset-2 {
    margin-left: 40%;
  }

  .col-sm-5th-offset-1 {
    margin-left: 20%;
  }

  .col-sm-5th-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-5th-1, .col-md-5th-2, .col-md-5th-3, .col-md-5th-4 {
    float: left;
  }

  .col-md-5th-5 {
    float: left;
    width: 100%;
  }

  .col-md-5th-4 {
    width: 80%;
  }

  .col-md-5th-3 {
    width: 60%;
  }

  .col-md-5th-2 {
    width: 40%;
  }

  .col-md-5th-1 {
    width: 20%;
  }

  .col-md-5th-pull-5 {
    right: 100%;
  }

  .col-md-5th-pull-4 {
    right: 80%;
  }

  .col-md-5th-pull-3 {
    right: 60%;
  }

  .col-md-5th-pull-2 {
    right: 40%;
  }

  .col-md-5th-pull-1 {
    right: 20%;
  }

  .col-md-5th-pull-0 {
    right: auto;
  }

  .col-md-5th-push-5 {
    left: 100%;
  }

  .col-md-5th-push-4 {
    left: 80%;
  }

  .col-md-5th-push-3 {
    left: 60%;
  }

  .col-md-5th-push-2 {
    left: 40%;
  }

  .col-md-5th-push-1 {
    left: 20%;
  }

  .col-md-5th-push-0 {
    left: auto;
  }

  .col-md-5th-offset-5 {
    margin-left: 100%;
  }

  .col-md-5th-offset-4 {
    margin-left: 80%;
  }

  .col-md-5th-offset-3 {
    margin-left: 60%;
  }

  .col-md-5th-offset-2 {
    margin-left: 40%;
  }

  .col-md-5th-offset-1 {
    margin-left: 20%;
  }

  .col-md-5th-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-5th-1, .col-lg-5th-2, .col-lg-5th-3, .col-lg-5th-4 {
    float: left;
  }

  .col-lg-5th-5 {
    float: left;
    width: 100%;
  }

  .col-lg-5th-4 {
    width: 80%;
  }

  .col-lg-5th-3 {
    width: 60%;
  }

  .col-lg-5th-2 {
    width: 40%;
  }

  .col-lg-5th-1 {
    width: 20%;
  }

  .col-lg-5th-pull-5 {
    right: 100%;
  }

  .col-lg-5th-pull-4 {
    right: 80%;
  }

  .col-lg-5th-pull-3 {
    right: 60%;
  }

  .col-lg-5th-pull-2 {
    right: 40%;
  }

  .col-lg-5th-pull-1 {
    right: 20%;
  }

  .col-lg-5th-pull-0 {
    right: auto;
  }

  .col-lg-5th-push-5 {
    left: 100%;
  }

  .col-lg-5th-push-4 {
    left: 80%;
  }

  .col-lg-5th-push-3 {
    left: 60%;
  }

  .col-lg-5th-push-2 {
    left: 40%;
  }

  .col-lg-5th-push-1 {
    left: 20%;
  }

  .col-lg-5th-push-0 {
    left: auto;
  }

  .col-lg-5th-offset-5 {
    margin-left: 100%;
  }

  .col-lg-5th-offset-4 {
    margin-left: 80%;
  }

  .col-lg-5th-offset-3 {
    margin-left: 60%;
  }

  .col-lg-5th-offset-2 {
    margin-left: 40%;
  }

  .col-lg-5th-offset-1 {
    margin-left: 20%;
  }

  .col-lg-5th-offset-0 {
    margin-left: 0%;
  }
}

@font-face {
    font-family: "icons";
    font-display: swap;
    src: url(/static/media/icons.03f71e9f.eot?#iefix) format("embedded-opentype"),
url(/static/media/icons.da6e0884.woff2) format("woff2"),
url(/static/media/icons.172f8208.woff) format("woff"),
url(/static/media/icons.323ee667.ttf) format("truetype"),
url(/static/media/icons.1e4a80b0.svg#icons) format("svg");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    /* font-weight: normal !important;
    font-variant: normal;
    text-transform: none; */
    line-height: 1;
    /* -webkit-font-smoothing: antialiased; */
    /* -moz-osx-font-smoothing: grayscale; */
    padding: 0rem 1rem 0rem 0rem; /* 0px 10px 0px 0px */
    font-size: 1.5rem; /* 15px */
    position: relative;
    top: 0.21rem; /* 2.1px */
}

.icon-search-icon:before {
    content: "\f101";
}
.icon-search-solid:before {
    content: "\f102";
}
.icon-up-arrow:before {
    content: "\f103";
}
.icon-user:before {
    content: "\f104";
}

/* 4k screen font scaling */

html {
    /* using 10px as root font size; all rem values scaled accordingly */
    font-size: 10px;
}

@media screen and (min-width: 2160px) {
    html {
        font-size: 20px;    /* fallback, incase calc does not work */
        /* calc to scale ui elements so that they maintain the size they have @2160px width */
        font-size: calc(100vw * 10 / 2160);
    }

    .issue-container .container {
        max-width: 125rem;
    }
}

/* other fixes */

@media (min-width: 1200px) {
    .container {
        max-width: 114rem /* 1140px */;
    }
}

/* prevent empty bar below footer */

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#root .content-wrapper {
    flex: 1 0;
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 114.0rem;
        margin: 4rem auto;
    }
}

