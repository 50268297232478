.searchFormControl{
    border: none !important;
    height:3.9rem /* 39px */ !important;
}

.searchForm{
    width: 100% !important;
    border-bottom: 0.1rem /* 1px */ solid #d0d0d0b0 !important;
    height: 4rem; /* 40px */
}
.searchFormControl::placeholder{
    font-style: italic;
}

.searchFormSubmit{
    background: #009DFF;
    border-radius: 0rem; /* 0px */
    font-size: 1.4rem; /* 14px */
    font-weight: 600;
    height:4rem; /* 40px */
}

.search-icon {
    margin-top: 0.5rem; /* 5px */
}

i[class^="icon-search-icon"]:before, i[class*=" icon-search-icon"]:before {
    font-size: 2.2rem /* 22px */ !important;
}

.has-search .form-control {
    /* padding-left: 2.375rem; */
    padding-left: 3.8rem; /* 38px */
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    /* width: 2.375rem; */
    width: 3.8rem; /* 38px */
    text-align: center;
    pointer-events: none;
    color: #aaa;
    border:none;
}

.searchFormControl:focus{
    box-shadow: none !important;
}