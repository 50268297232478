label{
    /* font-size: 1.8rem; */
    font-size: 2.88rem; /* 28.8px */
    font-style:none;
    /* font-family: Avenir LT; */
    font-weight: 400;
    cursor: pointer;
}

.card-text{
    clear: both;
    font-weight: 300;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;

}
.card-header{
    text-transform: uppercase;
    letter-spacing: 0.9rem; /* 9px */
    color: #18032d;
    /* font-size: 1.2rem; */
    font-size: 1.92rem; /* 19.2px */
    text-align: left;
    /* background-image: linear-gradient(to left, rgb(179, 197, 211), #FFFFFF); */
    background-color: transparent !important;
    /* padding:0rem; */
    padding:0;
    border-bottom: 0.1rem /* 1px */ solid #80808026;
    height: 2.5rem; /* 25px */
}

.card-body{
    margin:2rem 0rem 0rem 0rem; /* 20px 0px 0px 0px */
    /* padding: 0rem !important; */
    padding: 0 !important;
}

.product-card{
    padding: 0rem 0rem 0rem 0rem /* 0px 0px 0px 0px */ !important;
    margin: 4rem 5.5rem 0rem 5.5rem /* 40px 55px 0px 55px */ !important;

}

.product-container{
    margin-right: 0rem; /* 0px */
    margin-left: 0rem; /* 0px */
    /* margin-top: -5rem; */
    margin-top: -8rem; /* -80px */
    width: 100%;
    padding-right: 0rem; /* 0px */
    padding-left: 0rem; /* 0px */

}

@media (min-width: 576px) {
    .main-page-card-deck.card-deck .card {
        margin-right: 3rem;
        margin-left: 3rem;
    }
}

.main-page-card-deck.card-deck {
    margin: 1.5rem 3.5rem !important;
}

.main-page-card-deck.card-deck .card {
    border: none;
    padding: 3.2rem 0;
    margin-top: 2rem /* 20px */ !important;
}

.h3{
    color:#6E4B7C !important;
    /* font-size: 1.9rem !important; */
    font-size: 3.04rem /* 30.4px */ !important;
}
.category{
    margin-left:0.1rem; /* 1px */
    color:#616161cc;
    margin-bottom: 1.5rem; /* 15px */
    text-transform: uppercase;
    font-size: 1.7rem; /* 17px */

    /* border-bottom: 1px solid gray; */
    /* text-transform: uppercase; */
}

.manuals{
    background:transparent;
    margin-bottom: 1rem; /* 10px */
}

.manual-info{
    color:#18032d;
    font-size: 1.5rem; /* 15px */
    margin-bottom: 0rem; /* 0px */
}
.manual-info:hover{
    color:#009DFF;
}

.manuals:hover .manual-info{
    /* background: grey; */
    color:#009DFF !important;
}

.manual-card{
    cursor: pointer;

}

.go-top{
    position: fixed;
    cursor: pointer;
    bottom: 8rem; /* 80px */
    right: 6.5rem; /* 65px */
    width: 3.8rem; /* 38px */
    height: 3.8rem; /* 38px */
    text-align: center;
    box-shadow: 0rem 0rem 0.5rem 0.17rem /* 0px 0px 5px 1.7px */ #ada6a6;
    background: #009DFF;
    border-radius: 3.5rem; /* 35px */
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.icon-up-arrow{
    font-size: 2rem; /* 20px */
    line-height: 4.3rem; /* 43px */
    padding: 0rem 0rem 0rem 1.2rem; /* 0px 0px 0px 12px */
    color:#ffffff;
}

@media screen and (max-width: 768px){
    .product-card {
        padding: 0rem 2rem 0rem 0rem /* 0px 20px 0px 0px */ !important;
        margin: 3.5rem 0rem 0rem 1rem /* 35px 0px 0px 10px */ !important;
    }
}

#searchholderdiv .form-control::placeholder {
    /* move placeholder text in search a little down to align it with text */
    transform: translate(0, 0.2rem);
}