/* ==============================================================

Template name : Bootsnav - Multi Purpose Header
Categorie : Bootstrap Menu in CSS
Author : adamnurdin01
Version : v.1.1
Created : 2016-06-02
Last update : 2016-06-11

============================================================== */
/*
Typography
=========================== */
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    /* font-family: 'Avenir'; */
    position: relative;
    font-size: 1.4rem; /* 14px */
    line-height: 2.4rem; /* 24px */
    color: #6f6f6f;
}

a {
    color: #6f6f6f;
}

a,
a:active,
a:focus,
a:hover {
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a:hover {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #3f3f3f;
    margin: 0 0 1.5rem 0; /* 15px */
    letter-spacing: 0.1rem; /* 1px */
    font-weight: bold;
}

h1 {
    font-size: 4.8rem; /* 48px */
    line-height: 5.2rem; /* 52px */
}

h2 {
    font-size: 3.6rem; /* 36px */
    line-height: 4.8rem; /* 48px */
}

h3 {
    font-size: 3rem; /* 30px */
    line-height: 3.6rem; /* 36px */
}

h4 {
    font-size: 2.4rem; /* 24px */
    line-height: 3rem; /* 30px */
}

h5 {
    font-size: 1.8rem; /* 18px */
    line-height: 2.4rem; /* 24px */
}

h6 {
    font-size: 1.4rem; /* 14px */
    line-height: 1.8rem; /* 18px */
}

p {
    margin-bottom: 2rem; /* 20px */
}

.wrapper {
    min-height: 80rem; /* 800px */
}

/*
Navbar
=========================== */
/* Navbar Atribute ------*/
.attr-nav>ul>li>a {
    padding: 2.8rem 1.5rem; /* 28px 15px */
}

ul.cart-list>li.total>.btn {
    border-bottom: solid 0.1rem /* 1px */ #cfcfcf !important;
    color: #fff !important;
    padding: 1rem 1.5rem; /* 10px 15px */
}

@media (min-width: 1024px) {

    /* Navbar General ------*/
    nav.navbar ul.nav>li>a {
        padding: 3rem 1.5rem; /* 30px 15px */
        font-weight: 600;
    }

    nav.navbar .navbar-brand {
        margin-top: 0;
    }



    nav.navbar .navbar-brand {
        margin-top: 0;
    }

    nav.navbar .navbar-brand img.logo {
        position: relative;
        
        top: 0.2rem; /* 2px */
    }

    nav.navbar.sticked .navbar-brand img.logo {
        position: relative;
        top: 0.2rem; /* 2px */
        /* max-width: 80%; */
    }

    nav.navbar li.dropdown ul.dropdown-menu {
        border-top: solid 0.5rem; /* 5px */
    }

    /* Navbar Center ------*/
    nav.navbar-center .navbar-brand {
        margin: 0 !important;
    }

    /* Navbar Brand Top ------*/
    nav.navbar-brand-top .navbar-brand {
        margin: 1rem /* 10px */ !important;
    }

    /* Navbar Full ------*/
    nav.navbar-full .navbar-brand {
        position: relative;
        top: -1.5rem; /* -15px */
    }

    /* Navbar Sidebar ------*/
    nav.navbar-sidebar ul.nav,
    nav.navbar-sidebar .navbar-brand {
        margin-bottom: 5rem; /* 50px */
    }

    nav.navbar-sidebar ul.nav>li>a {
        padding: 1rem 1.5rem; /* 10px 15px */
        font-weight: bold;
    }

    /* Navbar Transparent & Fixed ------*/
    nav.navbar.bootsnav.navbar-transparent.white {
        background-color: rgba(255, 255, 255, 0.3);
        border-bottom: solid 0.1rem /* 1px */ #bbb;
    }

    nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark,
    nav.navbar.bootsnav.navbar-transparent.dark {
        background-color: rgba(0, 0, 0, 0.3);
        border-bottom: solid 0.1rem /* 1px */ #555;
    }

    nav.navbar.bootsnav.navbar-transparent.white .attr-nav {
        border-left: solid 0.1rem /* 1px */ #bbb;
    }

    nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav,
    nav.navbar.bootsnav.navbar-transparent.dark .attr-nav {
        border-left: solid 0.1rem /* 1px */ #555;
    }

    nav.navbar.bootsnav.no-background.white .attr-nav>ul>li>a,
    nav.navbar.bootsnav.navbar-transparent.white .attr-nav>ul>li>a,
    nav.navbar.bootsnav.navbar-transparent.white ul.nav>li>a,
    nav.navbar.bootsnav.no-background.white ul.nav>li>a {
        color: #fff;
    }

    nav.navbar.bootsnav.navbar-transparent.dark .attr-nav>ul>li>a,
    nav.navbar.bootsnav.navbar-transparent.dark ul.nav>li>a {
        color: #eee;
    }
}

@media (max-width: 992px) {

    /* Navbar General ------*/
    nav.navbar .navbar-brand {
        margin-top: 0;
        position: relative;
        top: -0.2rem; /* -2px */
    }

    nav.navbar .navbar-brand img.logo {
        width: 15rem; /* 150px */
        margin-top: 0.8rem; /* 8px */
        height: auto;
    }

    .attr-nav>ul>li>a {
        padding: 1.6rem 1.5rem 1.5rem; /* 16px 15px 15px */
    }

    /* Navbar Mobile slide ------*/
    nav.navbar.navbar-mobile ul.nav>li>a {
        padding: 1.5rem 1.5rem; /* 15px 15px */
    }

    nav.navbar.navbar-mobile ul.nav ul.dropdown-menu>li>a {
        padding-right: 1.5rem /* 15px */ !important;
        padding-top: 1.5rem /* 15px */ !important;
        padding-bottom: 1.5rem /* 15px */ !important;
    }

    nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title {
        padding-right: 3rem /* 30px */ !important;
        padding-top: 1.3rem /* 13px */ !important;
        padding-bottom: 1.3rem /* 13px */ !important;
    }

    nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu ul.menu-col li a {
        padding-top: 1.3rem /* 13px */ !important;
        padding-bottom: 1.3rem /* 13px */ !important;
    }

    /* Navbar Full ------*/
    nav.navbar-full .navbar-brand {
        top: 0;
        padding-top: 1rem; /* 10px */
    }
}

/* Navbar Inverse
=================================*/
nav.navbar.navbar-inverse {
    background-color: #222;
    border-bottom: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse ul.cart-list>li.total>.btn {
    border-bottom: solid 0.1rem /* 1px */ #222 !important;
}

nav.navbar.navbar-inverse ul.cart-list>li.total .pull-right {
    color: #fff;
}

nav.navbar.navbar-inverse.megamenu ul.dropdown-menu.megamenu-content .content ul.menu-col li a,
nav.navbar.navbar-inverse ul.nav>li>a {
    color: #eee;
}

nav.navbar.navbar-inverse ul.nav>li.dropdown>a {
    background-color: #222;
}

nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu>li>a {
    color: #999;
}

nav.navbar.navbar-inverse ul.nav .dropdown-menu h1,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h2,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h3,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h4,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h5,
nav.navbar.navbar-inverse ul.nav .dropdown-menu h6 {
    color: #fff;
}

nav.navbar.navbar-inverse .form-control {
    background-color: #333;
    border-color: #303030;
    color: #fff;
}

nav.navbar.navbar-inverse .attr-nav>ul>li>a {
    color: #eee;
}

nav.navbar.navbar-inverse .attr-nav>ul>li.dropdown ul.dropdown-menu {
    background-color: #222;
    border-left: solid 0.1rem /* 1px */ #303030;
    border-bottom: solid 0.1rem /* 1px */ #303030;
    border-right: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse ul.cart-list>li {
    border-bottom: solid 0.1rem /* 1px */ #303030;
    color: #eee;
}

nav.navbar.navbar-inverse ul.cart-list>li img {
    border: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse ul.cart-list>li.total {
    background-color: #333;
}

nav.navbar.navbar-inverse .share ul>li>a {
    background-color: #555;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu {
    border-right: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul>li>a {
    border-bottom: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-content {
    border-left: solid 0.1rem /* 1px */ #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul>li>a:hover,
nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul>li>a:focus,
nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul>li.active>a {
    background-color: #333 !important;
}

nav.navbar-inverse.navbar-full ul.nav>li>a {
    border: none;
}

nav.navbar-inverse.navbar-full .navbar-collapse .wrap-full-menu {
    background-color: #222;
}

nav.navbar-inverse.navbar-full .navbar-toggle {
    background-color: #222 !important;
    color: #6f6f6f;
}

@media (min-width: 1024px) {
    nav.navbar.navbar-inverse ul.nav .dropdown-menu {
        background-color: #222 !important;
        border-left: solid 0.1rem /* 1px */ #303030 !important;
        border-bottom: solid 0.1rem /* 1px */ #303030 !important;
        border-right: solid 0.1rem /* 1px */ #303030 !important;
    }

    nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu>li>a {
        border-bottom: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse ul.dropdown-menu.megamenu-content .col-menu {
        border-left: solid 0.1rem /* 1px */ #303030;
        border-right: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse.navbar-transparent.dark {
        background-color: rgba(0, 0, 0, 0.3);
        border-bottom: solid 0.1rem /* 1px */ #999;
    }

    nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav {
        border-left: solid 0.1rem /* 1px */ #999;
    }

    nav.navbar.navbar-inverse.no-background.white .attr-nav>ul>li>a,
    nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav>ul>li>a,
    nav.navbar.navbar-inverse.navbar-transparent.dark ul.nav>li>a,
    nav.navbar.navbar-inverse.no-background.white ul.nav>li>a {
        color: #fff;
    }

    nav.navbar.navbar-inverse.no-background.dark .attr-nav>ul>li>a,
    nav.navbar.navbar-inverse.no-background.dark .attr-nav>ul>li>a,
    nav.navbar.navbar-inverse.no-background.dark ul.nav>li>a,
    nav.navbar.navbar-inverse.no-background.dark ul.nav>li>a {
        color: #3f3f3f;
    }
}

@media (max-width: 992px) {
    nav.navbar.navbar-inverse .navbar-toggle {
        color: #eee;
        background-color: #222 !important;
    }

    nav.navbar.navbar-inverse .navbar-nav>li>a {
        border-top: solid 0.1rem /* 1px */ #303030;
        border-bottom: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse ul.nav li.dropdown ul.dropdown-menu>li>a {
        color: #999;
        border-bottom: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu .title {
        border-bottom: solid 0.1rem /* 1px */ #303030;
        color: #eee;
    }

    nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu ul>li>a {
        border-bottom: solid 0.1rem /* 1px */ #303030;
        color: #999 !important;
    }

    nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu.on:last-child .title {
        border-bottom: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse .dropdown-tabs .tab-menu>ul {
        border-top: solid 0.1rem /* 1px */ #303030;
    }

    nav.navbar.navbar-inverse.navbar-mobile .navbar-collapse {
        background-color: #222;
    }
}

@media (max-width: 767px) {
    nav.navbar.navbar-inverse.navbar-mobile ul.nav {
        border-top: solid 0.1rem /* 1px */ #222;
    }

    h1 {
        font-size: 3rem; /* 30px */
        line-height: 3.2rem; /* 32px */
    }

    h2 {
        font-size: 2.4rem; /* 24px */
        line-height: auto;
    }

    h3 {
        font-size: 2.6rem; /* 26px */
        line-height: 2.4rem; /* 24px */
    }

    h4 {
        font-size: 2rem; /* 20px */
        line-height: 2rem; /* 20px */
    }

    h5 {
        font-size: 1.6rem; /* 16px */
        line-height: auto;
    }

    h6 {
        font-size: 1.2rem; /* 12px */
        line-height: 1rem; /* 10px */
    }

    p {
        margin-bottom: 1rem; /* 10px */
    }
}