.search-bar{
    padding-top: 0.3rem; /* 3px */
    margin-right: 4rem; /* 40px */
    /* margin-top: 8rem; */
    margin-top: 12.8rem; /* 128px */
    margin-left: 4rem; /* 40px */
}

.css-tlfecz-indicatorContainer {
    color: #000000bf !important;
}

@media screen and (max-width: 768px) {
    .search-bar {
        margin-right: 1rem; /* 10px */
        margin-top: 1rem; /* 10px */
        margin-left: 1rem; /* 10px */
        /* padding-top: 4rem; */
        padding-top: 6.4rem; /* 64px */
    }
}