.usermenu{
    position: absolute !important;
    /* margin-top: -8rem; */
    margin-top: -12.8rem; /* -128px */
    width: fit-content !important;
    /* height: 7rem; */
    padding: 0rem 0rem 5.5rem 0rem /* 0px 0px 55px 0px */ !important;
    /* font-size: 1.2rem !important; */
    font-size: 1.92rem /* 19.2px */ !important;
    background-color: #f8f9fa !important;
    margin-right: 0.5rem; /* 5px */
    overflow: hidden;
}

.glyphicon-user{
    padding-right: 0.5rem; /* 5px */
    /* font-size: 1.3rem; */
    font-size: 2.08rem; /* 20.8px */
}

.glyphicon-user::before{
    margin-left:0.5rem; /* 5px */
    margin-right: 0.5rem; /* 5px */
    
}
.glyphicon-log-out::before{
    /* margin-right: 1rem; */
    margin-right: 1.6rem; /* 16px */
}

.userDropdown:hover{
    background-color:transparent !important;
    color:black !important;
}

.user-profile{
    margin-right: 4rem; /* 40px */
}
.user-profile-login{
    background: rgb(252, 206, 49);
    color: rgb(56, 56, 56);
    cursor: pointer;
    padding: 0.5rem 0.5rem 0rem 0.5rem; /* 5px 5px 0px 5px */
    margin-right: 4rem; /* 40px */
    display:block;
    font-size: 1.5rem; /* 15px */
}
.userPopoverTitle{
    font-size: 80%;
    padding: 0rem 1rem 0rem 0rem; /* 0px 10px 0px 0px */
}

@media (max-width: 767px) {
    .popover-user-drop{
        visibility: hidden;
    }
}
.popover-user-drop{
    position: fixed !important;
    min-width: 12.5rem; /* 125px */
    width: auto;
    top:0.1rem /* 1px */ !important;
    margin: 0.3rem 0rem 0rem 0rem; /* 3px 0px 0px 0px */
}

.popover-logout{
    padding: 0.5rem 0rem 0.5rem 0rem; /* 5px 0px 5px 0px */
}

.popover-logout:hover{
    cursor: pointer;
    background: #afaaaa42;
}

.popover-user-dropdown{
    height: 3.5rem; /* 35px */
    font-size: 1.4rem; /* 14px */
    padding: 0rem 0rem 0rem 1rem; /* 0px 0px 0px 10px */
    background:transparent;
}


.popover-user-dropdown:hover{
    text-decoration: none;
    color:#6f6f6f;
}

.popover .arrow::after, .popover .arrow::before {
    position: absolute;
    display: hidden;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.userProfileContainer{
    list-style: none;
    color:#A9A9A9;
    font-size: 1.2rem; /* 12px */
    background-color: #ececec;
    padding: 1rem 0rem 1rem 3rem; /* 10px 0px 10px 30px */
}

.userProfileSm{
    border-bottom: 0.1rem /* 1px */ solid #80808026;
    width: max-content;
    margin-bottom: 0.5rem; /* 5px */
}

.userLogoutSm>a{
    color:#A9A9A9;
}